import React, { useContext, useState } from "react";
import { DatePicker, Select } from "antd";
import StatCard from "../../components/statCard";
import LineChart1 from "../../components/line-chart";
import { GrDocumentDownload } from "react-icons/gr";
import TinyBarChart from "../../components/tinyBarChart";
import TinyLineChart from "../../components/tiny-line-chart";
import { Link } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ProfileCard from "../../components/profile-card";
import SupportBarChart from "../../components/barChart";
import SchoolBarChart from "../../components/schoolBarChart";
import ClassBarChart from "../../components/classBarChart";
import SchoolEmailUsageWidget from "../../components/schoolEmail";
import BarGraph from "../../components/barGraph";
import dayjs from "dayjs";
import IncomeGraph from "../../components/incomeData";
import DualGraph from "../../components/DualGraph";
import CustomPieChart from "../../components/pieChart";
import DualBarGraph from "../../components/dualBarGraph";
import rawData from "../../components/rawData";
const {
  data,
  dataSupportTicket,
  dataEmails,
  staticData,
  staticDataForRegisteredClasses,
  incomeData,
  staticDataSchool,
  staticDataForSentEmail,
  staticDataEmail,
  staticDataSupprtTicket,
  schoolData,
  emailSlotData,
  subscriptionData,
  activityData,
  staticDataForEmails,
  staticDataForSentAndReceivedEmail,
  participationData,
  salesData,
  emailsPerSchool,
  emailPerSchool,
} = rawData;

const Dashboard = () => {
  const [isActive, setIsActive] = useState(1);
  const { RangePicker } = DatePicker;
  const cardData = [
    {
      id: 1,
      name: "aktive Schulen",
      value: "12",
      perc: 1.3,
      stat: "down",
      comment: "seit letzten Jahr",
    },
    {
      id: 2,
      name: "aktive E-Mail Slots",
      value: "10.2 K",
      perc: 1.3,
      stat: "down",
      comment: "seit letzten Jahr",
    },
    {
      id: 3,
      name: "Einnahme",
      value: "13,7 K €",
      perc: 1.3,
      stat: "down",
      comment: "seit letzten Jahr",
    },
    {
      id: 4,
      name: "Offene Tickets",
      value: "7",
      perc: 1.3,
      stat: "down",
      comment: "seit letzten Jahr",
    },
    {
      id: 5,
      name: "Nicht verifiziert. E-Mails",
      value: "12",
      perc: 2.3,
      stat: "up",
      comment: "nicht verifizierte E-Mails",
    },
    {
      id: 6,
      name: "Avg. Order Value",
      value: "$306.20",
      perc: 1.3,
      stat: "down",
      comment: "seit letzten Jahr",
    },
    {
      id: 7,
      name: "Mailgun-Nutzung",
      value: "306",
      perc: 1.3,
      stat: "up",
      comment: "Gesamte Mailgun-E-Mail-Nutzung",
    },
    {
      id: 8,
      name: "neue Schulen",
      value: "306",
      perc: 1.3,
      stat: "up",
      comment: "Anzahl neuer Schulen",
    },

    {
      id: 9,
      name: "Support-Tickets",
      value: "306",
      perc: 1.3,
      stat: "up",
      comment: "Anzahl der Support-Tickets",
    },
    {
      id: 10,
      name: "Reg. Klassen",
      value: "306",
      perc: 1.3,
      stat: "up",
      comment: "Anzahl der Reg. Klassen",
    },
    {
      id: 11,
      name: "E-Mails gesendet",
      value: "36",
      perc: 1.8,
      stat: "down",
      comment: "Anzahl der gesendeten E-Mails",
    },
    {
      id: 12,
      name: "Registrierte Schulen",
      value: "36",
      perc: 1.8,
      stat: "up",
      comment: "Anzahl Reg. Schulen",
    },
    {
      id: 13,
      name: "aktive E-Mail-Slots",
      value: "36",
      perc: 1.8,
      stat: "up",
      comment: "NEIN. der aktiven E-Mail-Slots",
    },
    {
      id: 14,
      name: "Benutzeraktivität",
      value: "36",
      perc: 1.8,
      stat: "up",
      comment: "Benutzeraktivität Metriken",
    },
    {
      id: 15,
      name: "Öffnen Sie Support-Tickets",
      value: "306",
      perc: 1.3,
      stat: "up",
      comment: "Support-Tickets",
    },
  ];
  const supportCardData = [
    {
      id: 1,
      name: "Unterstützung  Anfragen",
      value: "12",
      perc: 1.3,
      stat: "down",
      comment: "seit letzten Jahr",
    },
    {
      id: 2,
      name: "gelöste Probleme",
      value: "10",
      perc: 1.8,
      stat: "up",
      comment: "seit letzten Jahr",
    },
    {
      id: 3,
      name: "Support-Tickets",
      value: "30",
      perc: 1.3,
      stat: "up",
      comment: "Anzahl der Support-Tickets",
    },
    {
      id: 4,
      name: "Offene Tickets",
      value: "30",
      // perc: 1.3,
      // stat: "up",
      comment: "Offene Tickets",
    },
    {
      id: 5,
      name: "Ausstehende Tickets",
      value: "10",
      // perc: 1.3,
      // stat: "up",
      comment: "Ausstehende Tickets",
    },
    {
      id: 6,
      name: "Geschlossene Tickets",
      value: "20",
      // perc: 1.3,
      // stat: "up",
      comment: "Geschlossene Tickets",
    },
    {
      id: 7,
      name: "Zeitreaktion",
      value: "20",
      // perc: 1.3,
      // stat: "up",
      comment: "Durchschnittliche Zeitreaktion",
    },
    {
      id: 8,
      name: "Systemfehler",
      value: "20",
      perc: 1.3,
      stat: "up",
      comment: "Gesamtfehler",
    },
    {
      id: 9,
      name: "Benutzeraktivität",
      value: "20",
      perc: 1.3,
      stat: "up",
      comment: "Benutzeraktivität Trends",
    },
    {
      id: 10,
      name: "Öffnen Sie Support-Tickets",
      value: "306",
      perc: 1.3,
      stat: "up",
      comment: "Support-Tickets",
    },
  ];
  const schoolCardData = [
    {
      id: 1,
      name: "Anzahl der angemeldeten Klassen",
      value: "50",
      perc: 1.3,
      stat: "down",
      comment: "seit letzten Jahr",
    },
    {
      id: 2,
      name: "Anzahl der aktiven E-Mail-Adressen",
      value: "200",
      perc: 1.8,
      stat: "up",
      comment: "seit letzten Jahr",
    },
    {
      id: 3,
      name: "Einzelne Schulen E-mail",
      value: "306",
      perc: 1.3,
      stat: "up",
      comment: "E-Mail-Nutzung einzelner Schulen",
    },
    {
      id: 4,
      name: "Reg. Klassen",
      value: "306",
      perc: 1.3,
      stat: "up",
      comment: "Anzahl der Reg. Klassen",
    },
    {
      id: 4,
      name: "Abonnement Status",
      value: "30",
      perc: 8.3,
      stat: "up",
      comment: "Aktiv",
    },
  ];
  const classCardData = [
    {
      id: 1,
      name: "Verified Email Addresses",
      value: "12",
      perc: 1.3,
      stat: "down",
      comment: "Anzahl der verifizierten E-Mail-Adressen",
    },
    {
      id: 2,
      name: "E-Mail gesendet",
      value: "10",
      perc: 1.3,
      stat: "down",
      comment: "Anzahl der gesendeten E-Mail-Adressen",
    },
    {
      id: 3,
      name: "E-Mail erhalten",
      value: "13",
      perc: 1.3,
      stat: "down",
      comment: "Anzahl der empfangenen E-Mail-Adressen",
    },
  ];
  const [user, setUser] = useState("school");
  return (
    <main>
      {user === "admin" && (
        <AdminDashboard
          isActive={isActive}
          setIsActive={setIsActive}
          RangePicker={RangePicker}
          cardData={cardData}
        />
      )}
      {user === "support" && (
        <SupportDashboard
          isActive={isActive}
          setIsActive={setIsActive}
          RangePicker={RangePicker}
          cardData={supportCardData}
        />
      )}
      {user === "school" && (
        <SchoolDashboard
          isActive={isActive}
          setIsActive={setIsActive}
          RangePicker={RangePicker}
          cardData={schoolCardData}
        />
      )}
      {user === "class" && <ClassDashboard classCardData={classCardData} />}
    </main>
  );
};

export default Dashboard;
const AdminDashboard = ({ isActive, setIsActive, RangePicker, cardData }) => {
  const [timeFrame, setTimeFrame] = useState("weekly");
  const [timeFrameSchool, setTimeFrameSchool] = useState("weekly");
  const [timeFrameEmail, setTimeFrameEmail] = useState("weekly");
  const [timeFrameTicket, setTimeFrameTicket] = useState("weekly");
  const [openSupportTicket, setOpenSupportTicket] = useState("weekly");
  const [timeFrameRegClasses, setTimeFrameRegClasses] = useState("weekly");
  const [timeFrameSentEmails, setTimeFrameSentEmails] = useState("weekly");
  const [view, setView] = useState("monthly");
  const [viewRegSchools, setViewRegSchools] = useState("monthly");
  const [viewActiveEmails, setViewActiveEmails] = useState("monthly");
  // Helper functions to filter the data
  const isToday = (date) => dayjs(date).isSame(dayjs(), "day");
  const isThisWeek = (date) => dayjs(date).isSame(dayjs(), "week");
  const isThisMonth = (date) => dayjs(date).isSame(dayjs(), "month");

  const getFilteredSchoolData = (timeFrameSchool) => {
    if (timeFrameSchool === "daily") {
      return staticDataSchool.filter((item) => isToday(item.date));
    } else if (timeFrameSchool === "weekly") {
      return staticDataSchool.filter((item) => isThisWeek(item.date));
    } else if (timeFrameSchool === "monthly") {
      return staticDataSchool.filter((item) => isThisMonth(item.date));
    }
  };

  const getFilteredSentEmail = (timeFrameSentEmails) => {
    if (timeFrameSentEmails === "daily") {
      return staticDataForSentEmail.filter((item) => isToday(item.date));
    } else if (timeFrameSentEmails === "weekly") {
      return staticDataForSentEmail.filter((item) => isThisWeek(item.date));
    } else if (timeFrameSentEmails === "monthly") {
      return staticDataForSentEmail.filter((item) => isThisMonth(item.date));
    }
  };

  const getFilteredRegisteredClasses = (timeFrameRegClasses) => {
    if (timeFrameRegClasses === "daily") {
      return staticDataForRegisteredClasses.filter((item) =>
        isToday(item.date)
      );
    } else if (timeFrameRegClasses === "weekly") {
      return staticDataForRegisteredClasses.filter((item) =>
        isThisWeek(item.date)
      );
    } else if (timeFrameRegClasses === "monthly") {
      return staticDataForRegisteredClasses.filter((item) =>
        isThisMonth(item.date)
      );
    }
  };
  const getFilteredData = (timeFrame) => {
    if (timeFrame === "daily") {
      return staticData.filter((item) => isToday(item.date));
    } else if (timeFrame === "weekly") {
      return staticData.filter((item) => isThisWeek(item.date));
    } else if (timeFrame === "monthly") {
      return staticData.filter((item) => isThisMonth(item.date));
    }
  };
  const getFilteredDataEmail = (timeFrame) => {
    if (timeFrameEmail === "daily") {
      return staticDataEmail.filter((item) => isToday(item.date));
    } else if (timeFrameEmail === "weekly") {
      return staticDataEmail.filter((item) => isThisWeek(item.date));
    } else if (timeFrameEmail === "monthly") {
      return staticDataEmail.filter((item) => isThisMonth(item.date));
    }
  };

  const getFilteredSupprtTickets = (timeFrameTicket) => {
    if (timeFrameTicket === "daily") {
      return staticDataSupprtTicket.filter((item) => isToday(item.date));
    } else if (timeFrameTicket === "weekly") {
      return staticDataSupprtTicket.filter((item) => isThisWeek(item.date));
    } else if (timeFrameTicket === "monthly") {
      return staticDataSupprtTicket.filter((item) => isThisMonth(item.date));
    }
  };
  const getFilteredOpenSupprtTickets = (openSupportTicket) => {
    if (openSupportTicket === "daily") {
      return staticDataSupprtTicket.filter((item) => isToday(item.date));
    } else if (openSupportTicket === "weekly") {
      return staticDataSupprtTicket.filter((item) => isThisWeek(item.date));
    } else if (openSupportTicket === "monthly") {
      return staticDataSupprtTicket.filter((item) => isThisMonth(item.date));
    }
  };

  // Get the data based on the current time frame
  const usageData = getFilteredData(timeFrame);
  const usageSchoolData = getFilteredSchoolData(timeFrameSchool);
  const usageEmailData = getFilteredDataEmail(timeFrameEmail);
  const usageSupportTicket = getFilteredSupprtTickets(timeFrameTicket);
  const usageOpenSupportTicket =
    getFilteredOpenSupprtTickets(openSupportTicket);
  const usageRegClasses = getFilteredRegisteredClasses(timeFrameRegClasses);
  const usageSentEmails = getFilteredSentEmail(timeFrameSentEmails);

  const handleChange = (value) => {
    if (value === "daily") {
      setTimeFrame("daily");
    } else if (value === "weekly") {
      setTimeFrame("weekly");
    } else if (value === "monthly") {
      setTimeFrame("monthly");
    }
  };
  const handleChangeSchool = (value) => {
    if (value === "daily") {
      setTimeFrameSchool("daily");
    } else if (value === "weekly") {
      setTimeFrameSchool("weekly");
    } else if (value === "monthly") {
      setTimeFrameSchool("monthly");
    }
  };
  const handleChangeEmail = (value) => {
    if (value === "daily") {
      setTimeFrameEmail("daily");
    } else if (value === "weekly") {
      setTimeFrameEmail("weekly");
    } else if (value === "monthly") {
      setTimeFrameEmail("monthly");
    }
  };
  const handleChangeSupportTicket = (value) => {
    if (value === "daily") {
      setTimeFrameTicket("daily");
    } else if (value === "weekly") {
      setTimeFrameTicket("weekly");
    } else if (value === "monthly") {
      setTimeFrameTicket("monthly");
    }
  };
  const handleChangeOpenSupportTicket = (value) => {
    if (value === "daily") {
      setOpenSupportTicket("daily");
    } else if (value === "weekly") {
      setOpenSupportTicket("weekly");
    } else if (value === "monthly") {
      setOpenSupportTicket("monthly");
    }
  };
  const handleChangeRegClasses = (value) => {
    if (value === "daily") {
      setTimeFrameRegClasses("daily");
    } else if (value === "weekly") {
      setTimeFrameRegClasses("weekly");
    } else if (value === "monthly") {
      setTimeFrameRegClasses("monthly");
    }
  };
  const handleChangeSentEmail = (value) => {
    if (value === "daily") {
      setTimeFrameSentEmails("daily");
    } else if (value === "weekly") {
      setTimeFrameSentEmails("weekly");
    } else if (value === "monthly") {
      setTimeFrameSentEmails("monthly");
    }
  };
  const handleChangeIncome = (value) => {
    if (value === "monthly") {
      setView("monthly");
    } else if (value === "total") {
      setView("total");
    }
  };
  const handleChangeRegSchools = (value) => {
    if (value === "monthly") {
      setViewRegSchools("monthly");
    } else if (value === "total") {
      setViewRegSchools("total");
    }
  };
  const handleChangeActiveEmails = (value) => {
    if (value === "monthly") {
      setViewActiveEmails("monthly");
    } else if (value === "total") {
      setViewActiveEmails("total");
    }
  };
  return (
    <main>
      <div className="flex md:flex-row flex-col justify-between items-center gap-3 dark:text-white">
        <h2 className="text-xl font-bold">Dashboard</h2>
        <div className="flex md:flex-row flex-col justify-end items-center gap-5">
          <div className="flex justify-end items-center">
            <button
              onClick={() => {
                setIsActive(1);
                handleChange("daily");
                handleChangeSentEmail("daily");

                handleChangeRegClasses("daily");
                handleChangeSupportTicket("daily");
                handleChangeEmail("daily");
                handleChangeSchool("daily");
              }}
              className={`${
                isActive === 1
                  ? "bg-blue-500 dark:bg-gray-700 text-white border-blue-500"
                  : "bg-white dark:bg-gray-500 dark:text-gray-700"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Tag
            </button>
            <button
              onClick={() => {
                setIsActive(2);
                handleChange("weekly");
                handleChangeSentEmail("weekly");
                handleChangeRegClasses("weekly");
                handleChangeSupportTicket("weekly");
                handleChangeEmail("weekly");
                handleChangeSchool("weekly");
              }}
              className={`${
                isActive === 2
                  ? "bg-blue-500 text-white dark:bg-gray-700 border-blue-500"
                  : "bg-white dark:bg-gray-500"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Woche
            </button>
            <button
              onClick={() => {
                setIsActive(3);
                handleChange("monthly");
                handleChangeSentEmail("monthly");
                handleChangeRegClasses("monthly");
                handleChangeSupportTicket("monthly");
                handleChangeEmail("monthly");
                setTimeFrameSchool("monthly");
              }}
              className={`${
                isActive === 3
                  ? "bg-blue-500 text-white dark:bg-gray-700 border-blue-500"
                  : "bg-white dark:bg-gray-500"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Monat
            </button>
          </div>
          <RangePicker className="w-[200px]" />
        </div>
      </div>

      <div className="mt-8 mb-4 grid lg:grid-cols-6 md:grid-cols-2 grid-cols-1 gap-3">
        {cardData.map((item) => (
          <StatCard key={item.id} data={item} />
        ))}
      </div>

      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Gesendete E-Mails</p>
          <Select
            defaultValue="letzten 12 Monate"
            className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
            style={{ width: 200, border: 0, fontSize: "10px" }}
            // onChange={handleChange}
            options={[
              { value: "letzten 12 Monate", label: "letzten 12 Monate" },
              { value: "letzten 15 Monate", label: "letzten 15 Monate" },
            ]}
          />
        </div>
        <LineChart1 data={dataEmails} title={"name"} title2={"Emails"} />
      </div>
      {/* Over Mailgun Email Usage  for admin and support user */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Gesamte Mailgun-E-Mail</p>
          {/* Timeframe selection */}
          <div className="flex justify-center space-x-4 mb-4">
            <Select
              defaultValue="daily"
              className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
              style={{ width: 200, border: 0, fontSize: "10px" }}
              onChange={handleChange}
              options={[
                { value: "daily", label: "täglich" },
                { value: "weekly", label: "wöchentlich" },
                { value: "monthly", label: "monatlich" },
              ]}
            />
          </div>
        </div>
        {/* Line Chart using Recharts */}
        <BarGraph usageData={usageData} title={"sent"} />
      </div>

      {/* New School Section  */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Anzahl neuer Schulen</p>
          <div className="flex justify-center space-x-4 mb-4">
            <Select
              defaultValue="daily"
              className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
              style={{ width: 200, border: 0, fontSize: "10px" }}
              onChange={handleChangeSchool}
              options={[
                { value: "daily", label: "täglich" },
                { value: "weekly", label: "wöchentlich" },
                { value: "monthly", label: "monatlich" },
              ]}
            />
          </div>
        </div>
        {/* <NewSchoolGraph usageData={usageSchoolData} /> */}
        <BarGraph usageData={usageSchoolData} title={"neueSchulen"} />
      </div>
      {/* Not Verified emails Section  */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">
            Anzahl nicht verifizierter E-Mails
          </p>
          <div className="flex justify-center space-x-4 mb-4">
            <Select
              defaultValue="daily"
              className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
              style={{ width: 200, border: 0, fontSize: "10px" }}
              onChange={handleChangeEmail}
              options={[
                { value: "daily", label: "täglich" },
                { value: "weekly", label: "wöchentlich" },
                { value: "monthly", label: "monatlich" },
              ]}
            />
          </div>
        </div>
        {/* <NotVerifiedEmails usageData={usageEmailData} /> */}
        <BarGraph usageData={usageEmailData} title={"nicht_verifiziert"} />
      </div>
      {/* Supprt Ticket Section  */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Anzahl der Support-Tickets</p>
          <div className="flex justify-center space-x-4 mb-4">
            <Select
              defaultValue="daily"
              className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
              style={{ width: 200, border: 0, fontSize: "10px" }}
              onChange={handleChangeSupportTicket}
              options={[
                { value: "daily", label: "täglich" },
                { value: "weekly", label: "wöchentlich" },
                { value: "monthly", label: "monatlich" },
              ]}
            />
          </div>
        </div>
        {/* <SupportTickets usageData={usageSupportTicket} /> */}
        <BarGraph usageData={usageSupportTicket} title={"support_tickets"} />
      </div>
      {/* Open Supprt Ticket Section  */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">
            Statistiken – Offene Support-Tickets
          </p>
          <div className="flex justify-center space-x-4 mb-4">
            <Select
              defaultValue={openSupportTicket}
              className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
              style={{ width: 200, border: 0, fontSize: "10px" }}
              onChange={handleChangeOpenSupportTicket}
              options={[
                { value: "daily", label: "täglich" },
                { value: "weekly", label: "wöchentlich" },
                { value: "monthly", label: "monatlich" },
              ]}
            />
          </div>
        </div>

        <BarGraph
          usageData={usageOpenSupportTicket}
          title={"support_tickets"}
        />
      </div>
      {/* Registered Classes */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Anzahl der angemeldeten Klassen</p>
          <div className="flex justify-center space-x-4 mb-4">
            <Select
              defaultValue="daily"
              className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
              style={{ width: 200, border: 0, fontSize: "10px" }}
              onChange={handleChangeRegClasses}
              options={[
                { value: "daily", label: "täglich" },
                { value: "weekly", label: "wöchentlich" },
                { value: "monthly", label: "monatlich" },
              ]}
            />
          </div>
        </div>
        {/* <SupportTickets usageData={usageSupportTicket} /> */}
        <BarGraph usageData={usageRegClasses} title={"angemeldete_Klassen"} />
      </div>
      {/* Sent emails */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Anzahl der gesendeten E-Mails</p>
          <div className="flex justify-center space-x-4 mb-4">
            <Select
              defaultValue="daily"
              className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
              style={{ width: 200, border: 0, fontSize: "10px" }}
              onChange={handleChangeSentEmail}
              options={[
                { value: "daily", label: "täglich" },
                { value: "weekly", label: "wöchentlich" },
                { value: "monthly", label: "monatlich" },
              ]}
            />
          </div>
        </div>
        {/* <SupportTickets usageData={usageSupportTicket} /> */}
        <BarGraph usageData={usageSentEmails} title={"sent"} />
      </div>
      {/* income data */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded mt-5">
        <div className="flex justify-between items-center mb-5">
          <p className="text-lg font-bold">Einkommenstrends</p>
          <Select
            defaultValue="monthly"
            onChange={handleChangeIncome}
            className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
            style={{ width: 200, border: 0, fontSize: "10px" }}
            options={[
              { value: "monthly", label: "monatlich" },
              { value: "total", label: "gesamt" },
            ]}
          />
        </div>
        {/* Monthly Income Bar Chart */}
        <IncomeGraph incomeData={incomeData} view={view} />
      </div>
      {/* Registered School Data */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded mt-5">
        <div className="flex justify-between items-center mb-5">
          <p className="text-lg font-bold">Anzahl der registrierten Schulen</p>
          <Select
            defaultValue="monthly"
            onChange={handleChangeRegSchools}
            className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
            style={{ width: 200, border: 0, fontSize: "10px" }}
            options={[
              { value: "monthly", label: "monatlich" },
              { value: "total", label: "gesamt" },
            ]}
          />
        </div>
        <DualGraph
          view={viewRegSchools}
          data={schoolData}
          title={"newRegistrations"}
          title_2={"totalRegistrations"}
        />
      </div>
      {/* Active Emails */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded mt-5">
        <div className="flex justify-between items-center mb-5">
          <p className="text-lg font-bold">Anzahl der aktiven E-Mail-Slots</p>
          <Select
            defaultValue="monthly"
            onChange={handleChangeActiveEmails}
            className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
            style={{ width: 200, border: 0, fontSize: "10px" }}
            options={[
              { value: "monthly", label: "monatlich" },
              { value: "total", label: "gesamt" },
            ]}
          />
        </div>
        <DualGraph
          view={viewActiveEmails}
          data={emailSlotData}
          title={"newSlots"}
          title_2={"totalSlots"}
        />
      </div>
      {/* Report on Schools and their email limit */}
      <div className="grid grid-cols-1 mt-4 justify-between items-start gap-4">
        <div className="lg:col-span-1">
          <div className="card w-full bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded">
            <div className="flex md:flex-row flex-col justify-between items-start gap-2 md:items-center">
              <p className="text-lg font-bold">E-Mail-Limit pro Schule</p>
              <button className="btn border border-gray-300 dark:text-white  text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                <GrDocumentDownload />
                <span>Export</span>
              </button>
            </div>

            <div className="mt-14">
              <TinyBarChart
                title={"emails"}
                title2={"school_title"}
                data={emailsPerSchool}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-3 grid-cols-1 mt-4 justify-between items-start gap-4">
        <div className="lg:col-span-2">
          <div className="card w-full bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded">
            <div className="flex md:flex-row flex-col justify-between items-start gap-2 md:items-center">
              <p className="text-lg font-bold">Sales Report</p>
              <button className="btn border border-gray-300 dark:text-white  text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                <GrDocumentDownload />
                <span>Export</span>
              </button>
            </div>

            <div className="mt-14">
              <TinyBarChart
                title={"Einnahmen"}
                title2={"Ausgaben"}
                data={salesData}
              />
            </div>
          </div>
        </div>

        <div className="col-span-1 h-full">
          <div className="card flex flex-col justify-center h-full w-full bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded">
            <div className="flex justify-between items-center">
              <p className="text-lg font-bold">MAILGUN LIMIT</p>
            </div>
            <h3 className="mt-6 text-2xl font-bold">15.149 / 50.000</h3>

            <div className="flex mt-3 justify-start items-center gap-2">
              <span className={`font-bold text-sm text-green-500 `}>1.8%</span>
              <span className={`font-bold text-sm mb-1 text-green-500`}>↑</span>
              <span
                className={`font-normal text-sm mb-1 text-gray-400 dark:text-white`}
              >
                in diesen Monat
              </span>
            </div>

            <div className="line mt-6 h-[1px] bg-black dark:bg-gray-100"></div>
            <TinyLineChart />
            <div className="line mt-6 h-[1px] bg-black dark:bg-gray-100"></div>

            <Link
              to="/"
              className="text-blue-500 font-semibold text-sm mt-6 flex items-center gap-2"
            >
              Mailgun Updaten <FaChevronRight />
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded mt-5 ">
        <h2 className="text-xl font-bold">Aktivität Statistiken</h2>

        <div className="mt-14 ">
          <SupportBarChart
            title={"name"}
            title2={"Schule"}
            title3={"Klassen"}
            title4={"Registrierte"}
            data={data}
          />
        </div>
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 mt-4 justify-between items-start gap-4">
        <div className="lg:col-span-2">
          <div className="card w-full bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded">
            <div className="h-[200px] overflow-y-auto">
              <table className="table w-[180%] md:w-full">
                <thead>
                  <th className="border p-2 text-sm dark:bg-gray-600 ">
                    SCHULE
                  </th>
                  <th className="border p-2 text-sm dark:bg-gray-600  ">
                    THEMA
                  </th>
                  <th className="border p-2 text-sm dark:bg-gray-600 ">
                    KATEGORIE
                  </th>
                  <th className="border p-2 text-sm dark:bg-gray-600">
                    TICKET-NR.
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td className="border p-2 text-sm text-center">
                      Paid search
                    </td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">NORMAL</td>
                    <td className="border p-2 text-sm text-center">#0124</td>
                  </tr>
                  <tr>
                    <td className="border p-2 text-sm text-center">
                      Organic search
                    </td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">KRITISCH</td>
                    <td className="border p-2 text-sm text-center">#0125</td>
                  </tr>
                  <tr>
                    <td className="border p-2 text-sm text-center">Email</td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">WICHTIG</td>
                    <td className="border p-2 text-sm text-center">#0126</td>
                  </tr>
                  <tr>
                    <td className="border p-2 text-sm text-center">
                      Paid search
                    </td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">NORMAL</td>
                    <td className="border p-2 text-sm text-center">#0127</td>
                  </tr>
                  <tr>
                    <td className="border p-2 text-sm text-center">
                      Paid search
                    </td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">NORMAL</td>
                    <td className="border p-2 text-sm text-center">#0127</td>
                  </tr>
                  <tr>
                    <td className="border p-2 text-sm text-center">
                      Paid search
                    </td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">NORMAL</td>
                    <td className="border p-2 text-sm text-center">#0127</td>
                  </tr>
                  <tr>
                    <td className="border p-2 text-sm text-center">
                      Paid search
                    </td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">NORMAL</td>
                    <td className="border p-2 text-sm text-center">#0127</td>
                  </tr>
                  <tr>
                    <td className="border p-2 text-sm text-center">
                      Paid search
                    </td>
                    <td className="border p-2 text-sm text-center">
                      "Wie funktioniert das alles*
                    </td>
                    <td className="border p-2 text-sm text-center">NORMAL</td>
                    <td className="border p-2 text-sm text-center">#0127</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="pagination mt-5 flex justify-center items-center gap-2">
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                <FaChevronLeft />
              </button>
              <button className="btn bg-blue-500 text-white px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                1
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                2
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                3
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                4
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                5
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                <FaChevronRight />
              </button>
            </div>
          </div>
        </div>
        <div className="col-span-1 h-full">
          <div className="card h-full w-full bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded">
            <div className="flex justify-between items-center">
              <p className="text-lg font-bold">Neue Schulen</p>
            </div>

            <ProfileCard name="Herr Müller" id="01223" />
            <ProfileCard name="Herr Müller" id="01223" />
            <ProfileCard name="Herr Müller" id="01223" />

            <Link
              to="/"
              className="text-blue-500 font-semibold text-sm mt-6 flex items-center gap-2"
            >
              Mailgun Updaten <FaChevronRight />
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};
const SupportDashboard = ({ isActive, setIsActive, RangePicker, cardData }) => {
  const logsData = [
    {
      id: "001",
      timestamp: "2024-08-20 08:30:00",
      activityType: "Registration",
      userId: "#0124",
      userRole: "Student",
      details: "New student registered",
      status: "Successful",
      ipAddress: "192.168.1.1",
    },
    {
      id: "002",
      timestamp: "2024-08-20 08:30:00",
      activityType: "Registration",
      userId: "#0124",
      userRole: "Student",
      details: "New student registered",
      status: "Successful",
      ipAddress: "192.168.1.1",
    },
    {
      id: "003",
      timestamp: "2024-08-20 08:30:00",
      activityType: "Registration",
      userId: "#0124",
      userRole: "Teacher",
      details: "New student registered",
      status: "Successful",
      ipAddress: "192.168.1.1",
    },
    {
      id: "004",
      timestamp: "2024-08-20 08:30:00",
      activityType: "Registration",
      userId: "#0124",
      userRole: "Student",
      details: "New student registered",
      status: "Successful",
      ipAddress: "192.168.1.1",
    },
    {
      id: "005",
      timestamp: "2024-08-20 08:30:00",
      activityType: "Registration",
      userId: "#0124",
      userRole: "Teacher",
      details: "New student registered",
      status: "Successful",
      ipAddress: "192.168.1.1",
    },
  ];
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLogs, setFilteredLogs] = useState(logsData);
  const [timeFrameTicket, setTimeFrameTicket] = useState("weekly");
  const [timeFrameError, setTimeFrameError] = useState("weekly");
  const [timeFrameActivity, setTimeFrameeActivity] = useState("weekly");
  const [openSupportTicket, setOpenSupportTicket] = useState("weekly");

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    setFilteredLogs(
      logsData.filter(
        (log) =>
          log.userRole.toLowerCase().includes(term) ||
          log.details.toLowerCase().includes(term)
      )
    );
  };

  const staticSystemError = [
    { date: "2024-10-03", systemfehler: 600 },
    { date: "2024-09-25", systemfehler: 700 },
    { date: "2024-10-01", systemfehler: 367 },
    { date: "2024-09-28", systemfehler: 700 },
    { date: "2024-09-29", systemfehler: 800 },
    { date: "2024-10-22", systemfehler: 700 },
    { date: "2024-09-21", systemfehler: 800 },
    { date: "2024-08-24", systemfehler: 600 },
    { date: "2024-05-25", systemfehler: 700 },
    { date: "2024-10-26", systemfehler: 800 },
  ];
  const dataAverageTime = [
    { date: "2024-09-30", Durchschnittliche_Zeitreaktion: 600 },
    { date: "2024-09-25", Durchschnittliche_Zeitreaktion: 700 },
    { date: "2024-09-27", Durchschnittliche_Zeitreaktion: 500 },
    { date: "2024-09-28", Durchschnittliche_Zeitreaktion: 200 },
    { date: "2024-09-29", Durchschnittliche_Zeitreaktion: 100 },
    { date: "2024-09-22", Durchschnittliche_Zeitreaktion: 300 },
    { date: "2024-09-21", Durchschnittliche_Zeitreaktion: 300 },
    { date: "2024-08-24", Durchschnittliche_Zeitreaktion: 600 },
    { date: "2024-05-25", Durchschnittliche_Zeitreaktion: 700 },
    { date: "2024-03-26", Durchschnittliche_Zeitreaktion: 800 },
  ];
  const activityData = [
    { date: "2024-10-03", logins: 20 },
    { date: "2024-10-02", logins: 70 },
    { date: "2024-10-01", logins: 50 },
    { date: "2024-09-28", logins: 20 },
    { date: "2024-09-29", logins: 10 },
    { date: "2024-09-22", logins: 30 },
    { date: "2024-09-21", logins: 30 },
    { date: "2024-08-24", logins: 60 },
    { date: "2024-05-25", logins: 70 },
    { date: "2024-03-26", logins: 80 },
  ];
  // Helper functions to filter the data
  const isToday = (date) => dayjs(date).isSame(dayjs(), "day");
  const isThisWeek = (date) => dayjs(date).isSame(dayjs(), "week");
  const isThisMonth = (date) => dayjs(date).isSame(dayjs(), "month");
  const getFilteredSupprtTickets = (timeFrameTicket) => {
    if (timeFrameTicket === "daily") {
      return staticDataSupprtTicket.filter((item) => isToday(item.date));
    } else if (timeFrameTicket === "weekly") {
      return staticDataSupprtTicket.filter((item) => isThisWeek(item.date));
    } else if (timeFrameTicket === "monthly") {
      return staticDataSupprtTicket.filter((item) => isThisMonth(item.date));
    }
  };

  const getFilteredError = (timeFrameError) => {
    if (timeFrameError === "daily") {
      return staticSystemError.filter((item) => isToday(item.date));
    } else if (timeFrameError === "weekly") {
      return staticSystemError.filter((item) => isThisWeek(item.date));
    } else if (timeFrameError === "monthly") {
      return staticSystemError.filter((item) => isThisMonth(item.date));
    }
  };

  const getFilteredActivity = (timeFrameActivity) => {
    if (timeFrameActivity === "daily") {
      return activityData.filter((item) => isToday(item.date));
    } else if (timeFrameActivity === "weekly") {
      return activityData.filter((item) => isThisWeek(item.date));
    } else if (timeFrameActivity === "monthly") {
      return activityData.filter((item) => isThisMonth(item.date));
    }
  };
  const getFilteredOpenSupprtTickets = (openSupportTicket) => {
    if (openSupportTicket === "daily") {
      return staticDataSupprtTicket.filter((item) => isToday(item.date));
    } else if (openSupportTicket === "weekly") {
      return staticDataSupprtTicket.filter((item) => isThisWeek(item.date));
    } else if (openSupportTicket === "monthly") {
      return staticDataSupprtTicket.filter((item) => isThisMonth(item.date));
    }
  };
  const usageOpenSupportTicket =
    getFilteredOpenSupprtTickets(openSupportTicket);
  const usageSupportTicket = getFilteredSupprtTickets(timeFrameTicket);
  const usageSupportError = getFilteredError(timeFrameError);
  const usageSupportActivity = getFilteredActivity(timeFrameActivity);
  const handleChangeOpenSupportTicket = (value) => {
    if (value === "daily") {
      setOpenSupportTicket("daily");
    } else if (value === "weekly") {
      setOpenSupportTicket("weekly");
    } else if (value === "monthly") {
      setOpenSupportTicket("monthly");
    }
  };
  const handleChangeSupportTicket = (value) => {
    if (value === "daily") {
      setTimeFrameTicket("daily");
    } else if (value === "weekly") {
      setTimeFrameTicket("weekly");
    } else if (value === "monthly") {
      setTimeFrameTicket("monthly");
    }
  };
  const handleChangeError = (value) => {
    if (value === "daily") {
      setTimeFrameError("daily");
    } else if (value === "weekly") {
      setTimeFrameError("weekly");
    } else if (value === "monthly") {
      setTimeFrameError("monthly");
    }
  };
  const handleChangeActivity = (value) => {
    if (value === "daily") {
      setTimeFrameeActivity("daily");
    } else if (value === "weekly") {
      setTimeFrameeActivity("weekly");
    } else if (value === "monthly") {
      setTimeFrameeActivity("monthly");
    }
  };

  return (
    <main>
      <div className="flex md:flex-row flex-col dark:text-white justify-between items-center gap-3">
        <h2 className="text-xl font-bold">Dashboard</h2>
        <div className="flex md:flex-row flex-col justify-end items-center gap-5">
          <div className="flex justify-end items-center">
            <button
              onClick={() => {
                setIsActive(1);
                handleChangeSupportTicket("daily");
              }}
              className={`${
                isActive === 1
                  ? "bg-blue-500 dark:bg-gray-700 text-white border-blue-500"
                  : "bg-white dark:bg-gray-500 dark:text-gray-700"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Tag
            </button>
            <button
              onClick={() => {
                setIsActive(2);
                handleChangeSupportTicket("daily");
              }}
              className={`${
                isActive === 2
                  ? "bg-blue-500 dark:bg-gray-700 text-white border-blue-500"
                  : "bg-white dark:bg-gray-500 dark:text-gray-700"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Woche
            </button>
            <button
              onClick={() => {
                setIsActive(3);
                handleChangeSupportTicket("daily");
              }}
              className={`${
                isActive === 3
                  ? "bg-blue-500 dark:bg-gray-700 text-white border-blue-500"
                  : "bg-white dark:bg-gray-500 dark:text-gray-700"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Monat
            </button>
          </div>
          <RangePicker className="w-[200px]" />
        </div>
      </div>

      <div className="mt-8 mb-4 grid lg:grid-cols-4 grid-cols-1 gap-3">
        {cardData.map((item) => (
          <StatCard key={item.id} data={item} />
        ))}
      </div>

      <div className="grid grid-cols-1 mt-4 justify-between items-start gap-4">
        <div className="lg:col-span-2">
          <div className="card w-full bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded ">
            <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center my-5">
              <p className="text-[12px] py-3 text-left w-[200px] px-3">
                Suchen
              </p>
              <input
                type="text"
                name="search"
                placeholder="hier suchen..."
                value={searchTerm}
                onChange={handleSearch}
                className="input outline-none border-none p-3 bg-gray-200 w-full rounded-[6px] dark:bg-gray-500 dark:placeholder:text-gray-300"
              />
            </div>
            <div className="h-[300px] overflow-y-auto">
              <table className="table w-[180%] md:w-full">
                <thead>
                  <th className="border p-2 text-sm">Protokoll-ID</th>
                  <th className="border p-2 text-sm">Zeitstempel</th>
                  <th className="border p-2 text-sm">Aktivitätstyp</th>
                  <th className="border p-2 text-sm">Benutzer-ID</th>
                  <th className="border p-2 text-sm">Benutzerrolle</th>
                  <th className="border p-2 text-sm">Einzelheiten</th>
                  <th className="border p-2 text-sm">Status</th>
                  <th className="border p-2 text-sm">IP-Adresse</th>
                </thead>
                <tbody>
                  {filteredLogs.length > 0 ? (
                    filteredLogs.map((log) => (
                      <tr key={log.id}>
                        <td className="border p-2 text-sm text-center">
                          {log.id}
                        </td>
                        <td className="border p-2 text-sm text-center">
                          {log.timestamp}
                        </td>
                        <td className="border p-2 text-sm text-center">
                          {log.activityType}
                        </td>
                        <td className="border p-2 text-sm text-center">
                          {log.userId}
                        </td>
                        <td className="border p-2 text-sm text-center">
                          {log.userRole}
                        </td>
                        <td className="border p-2 text-sm text-center">
                          {log.details}
                        </td>
                        <td className="border p-2 text-sm text-center">
                          {log.status}
                        </td>
                        <td className="border p-2 text-sm text-center">
                          {log.ipAddress}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="8"
                        className="border p-2 text-sm text-center text-red-600"
                      >
                        Keine Ergebnisse gefunden
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="pagination mt-5 flex justify-center items-center gap-2">
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                <FaChevronLeft />
              </button>
              <button className="btn bg-blue-500 text-white px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                1
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                2
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                3
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                4
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                5
              </button>
              <button className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                <FaChevronRight />
              </button>
            </div>
          </div>
        </div>
        {/* <div className="col-span-1 h-full">
          <div className="card h-full w-full bg-white px-5 py-7 rounded">
            <div className="flex justify-between items-center">
              <p className="text-lg font-bold">Neue Schulen</p>
            </div>

            <ProfileCard name="Herr Müller" id="01223" />
            <ProfileCard name="Herr Müller" id="01223" />
            <ProfileCard name="Herr Müller" id="01223" />

            <Link
              to="/"
              className="text-blue-500 font-semibold text-sm mt-6 flex items-center gap-2"
            >
              Mailgun Updaten <FaChevronRight />
            </Link>
          </div>
        </div> */}
      </div>
      {/* Supprt Ticket Section  */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Anzahl der Support-Tickets</p>
          <div className="flex justify-center space-x-4 mb-4">
            <Select
              defaultValue="daily"
              className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
              style={{ width: 200, border: 0, fontSize: "10px" }}
              onChange={handleChangeSupportTicket}
              options={[
                { value: "daily", label: "täglich" },
                { value: "weekly", label: "wöchentlich" },
                { value: "monthly", label: "monatlich" },
              ]}
            />
          </div>
        </div>
        <BarGraph usageData={usageSupportTicket} title={"support_tickets"} />
      </div>
      <div className="w-full bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded mt-5 ">
        <div className="flex md:flex-row flex-col justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Aktivität Statistiken</p>
          <button className="btn border border-gray-300 dark:border-white dark:text-white text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
            <GrDocumentDownload />
            <span>Export</span>
          </button>
        </div>
        <div className="mt-14 ">
          <SupportBarChart
            title={"name"}
            title2={"Schule"}
            title3={"Klassen"}
            title4={"Registrierte"}
            data={data}
          />
        </div>
      </div>
      <div className="w-full bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded mt-5 ">
        <div className="flex md:flex-row flex-col justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Support-Tickets</p>
          <button className="btn border border-gray-300 dark:border-white dark:text-white text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
            <GrDocumentDownload />
            <span>Export</span>
          </button>
        </div>
        <div className="mt-14 ">
          <SupportBarChart
            title={"name"}
            title2={"open"}
            title3={"resolved"}
            title4={"pending"}
            data={dataSupportTicket}
          />
        </div>
      </div>
      {/* Open Supprt Ticket Section  */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">
            Statistiken – Offene Support-Tickets
          </p>
          <div className="flex justify-center space-x-4 mb-4">
            <Select
              defaultValue={openSupportTicket}
              className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
              style={{ width: 200, border: 0, fontSize: "10px" }}
              onChange={handleChangeOpenSupportTicket}
              options={[
                { value: "daily", label: "täglich" },
                { value: "weekly", label: "wöchentlich" },
                { value: "monthly", label: "monatlich" },
              ]}
            />
          </div>
        </div>

        <BarGraph
          usageData={usageOpenSupportTicket}
          title={"support_tickets"}
        />
      </div>
      {/* Report on Schools and their email limit */}
      <div className="grid grid-cols-1 mt-4 justify-between items-start gap-4">
        <div className="lg:col-span-1">
          <div className="card w-full bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded">
            <div className="flex md:flex-row flex-col justify-between items-start gap-2 md:items-center">
              <p className="text-lg font-bold">E-Mail-Limit pro Schule</p>
              <button className="btn border border-gray-300 dark:text-white  text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
                <GrDocumentDownload />
                <span>Export</span>
              </button>
            </div>

            <div className="mt-14">
              <TinyBarChart
                title={"emails"}
                title2={"school_title"}
                data={emailsPerSchool}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Ansprechzeit Trend</p>
          <Select
            defaultValue="letzten 12 Monate"
            className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
            style={{ width: 200, border: 0, fontSize: "10px" }}
            // onChange={handleChange}
            options={[
              { value: "letzten 12 Monate", label: "letzten 12 Monate" },
              { value: "letzten 15 Monate", label: "letzten 15 Monate" },
            ]}
          />
        </div>
        <LineChart1
          data={dataAverageTime}
          title={"date"}
          title2={"Durchschnittliche_Zeitreaktion"}
        />
      </div>
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Systemfehler</p>
          <div className="flex justify-center space-x-4 mb-4">
            <Select
              defaultValue="daily"
              className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
              style={{ width: 200, border: 0, fontSize: "10px" }}
              onChange={handleChangeError}
              options={[
                { value: "daily", label: "täglich" },
                { value: "weekly", label: "wöchentlich" },
                { value: "monthly", label: "monatlich" },
              ]}
            />
          </div>
        </div>
        <BarGraph usageData={usageSupportError} title={"systemfehler"} />
      </div>
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Benutzeraktivität Trends</p>
          <div className="flex justify-center space-x-4 mb-4">
            <Select
              defaultValue="daily"
              className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
              style={{ width: 200, border: 0, fontSize: "10px" }}
              onChange={handleChangeActivity}
              options={[
                { value: "daily", label: "täglich" },
                { value: "weekly", label: "wöchentlich" },
                { value: "monthly", label: "monatlich" },
              ]}
            />
          </div>
        </div>
        <BarGraph usageData={usageSupportActivity} title={"logins"} />
      </div>
    </main>
  );
};
const SchoolDashboard = ({ isActive, setIsActive, RangePicker, cardData }) => {
  const [timeFrameRegClasses, setTimeFrameRegClasses] = useState("weekly");
  const [viewActiveEmails, setViewActiveEmails] = useState("monthly");

  // Helper functions to filter the data
  const isToday = (date) => dayjs(date).isSame(dayjs(), "day");
  const isThisWeek = (date) => dayjs(date).isSame(dayjs(), "week");
  const isThisMonth = (date) => dayjs(date).isSame(dayjs(), "month");
  const getFilteredRegisteredClasses = (timeFrameRegClasses) => {
    if (timeFrameRegClasses === "daily") {
      return staticDataForRegisteredClasses.filter((item) =>
        isToday(item.date)
      );
    } else if (timeFrameRegClasses === "weekly") {
      return staticDataForRegisteredClasses.filter((item) =>
        isThisWeek(item.date)
      );
    } else if (timeFrameRegClasses === "monthly") {
      return staticDataForRegisteredClasses.filter((item) =>
        isThisMonth(item.date)
      );
    }
  };
  const usageRegClasses = getFilteredRegisteredClasses(timeFrameRegClasses);
  const handleChangeRegClasses = (value) => {
    if (value === "daily") {
      setTimeFrameRegClasses("daily");
    } else if (value === "weekly") {
      setTimeFrameRegClasses("weekly");
    } else if (value === "monthly") {
      setTimeFrameRegClasses("monthly");
    }
  };

  const handleChangeActiveEmails = (value) => {
    if (value === "monthly") {
      setViewActiveEmails("monthly");
    } else if (value === "total") {
      setViewActiveEmails("total");
    }
  };

  return (
    <main>
      <div className="flex md:flex-row flex-col dark:text-white justify-between items-center gap-3">
        <h2 className="text-xl font-bold">Dashboard</h2>
        <div className="flex md:flex-row flex-col justify-end items-center gap-5">
          <div className="flex justify-end items-center">
            <button
              onClick={() => setIsActive(1)}
              className={`${
                isActive === 1
                  ? "bg-blue-500 dark:bg-gray-700 text-white border-blue-500"
                  : "bg-white dark:bg-gray-500 dark:text-gray-700"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Tag
            </button>
            <button
              onClick={() => setIsActive(2)}
              className={`${
                isActive === 2
                  ? "bg-blue-500 dark:bg-gray-700 text-white border-blue-500"
                  : "bg-white dark:bg-gray-500 dark:text-gray-700"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Woche
            </button>
            <button
              onClick={() => setIsActive(3)}
              className={`${
                isActive === 3
                  ? "bg-blue-500 dark:bg-gray-700 text-white border-blue-500"
                  : "bg-white dark:bg-gray-500 dark:text-gray-700"
              } hover:bg-blue-500 dark:hover:bg-gray-500 hover:text-white border border-gray-300 rounded px-3 py-1 text-sm`}
            >
              Monat
            </button>
          </div>
          <RangePicker className="w-[200px]" />
        </div>
      </div>
      <div className="mt-8 mb-4 grid lg:grid-cols-3 grid-cols-1 gap-3">
        {cardData.map((item) => (
          <StatCard key={item.id} data={item} />
        ))}
        <div className="shadow rounded bg-white dark:bg-gray-700 p-3">
          <p className="text-gray-400 dark:text-gray-200  text-xs mb-3">
            Abonnement status
          </p>
          <h3 className="text-xl font-semibold text-black dark:text-white">
            WAHR
          </h3>
        </div>
      </div>
      <div className="w-full bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded mt-5 ">
        <div className="flex md:flex-row flex-col justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Aktivitäts daten</p>
          <button className="btn border border-gray-300 dark:border-white dark:text-white text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
            <GrDocumentDownload />
            <span>Export</span>
          </button>
        </div>
        <div className="mt-14 ">
          <SchoolBarChart />
        </div>
      </div>
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Anzahl der angemeldeten Klassen</p>
          <div className="flex justify-center space-x-4 mb-4">
            <Select
              defaultValue="daily"
              className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
              style={{ width: 200, border: 0, fontSize: "10px" }}
              onChange={handleChangeRegClasses}
              options={[
                { value: "daily", label: "täglich" },
                { value: "weekly", label: "wöchentlich" },
                { value: "monthly", label: "monatlich" },
              ]}
            />
          </div>
        </div>
        {/* <SupportTickets usageData={usageSupportTicket} /> */}
        <BarGraph usageData={usageRegClasses} title={"angemeldete_Klassen"} />
      </div>
      {/* Active Emails */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded mt-5">
        <div className="flex justify-between items-center mb-5">
          <p className="text-lg font-bold">Anzahl der aktiven E-Mail-Slots</p>
          <Select
            defaultValue="monthly"
            onChange={handleChangeActiveEmails}
            className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
            style={{ width: 200, border: 0, fontSize: "10px" }}
            options={[
              { value: "monthly", label: "monatlich" },
              { value: "total", label: "gesamt" },
            ]}
          />
        </div>
        <DualGraph
          view={viewActiveEmails}
          data={emailSlotData}
          title={"newSlots"}
          title_2={"totalSlots"}
        />
      </div>
      {/* Subscription status */}
      <div></div>
      <div className="grid lg:grid-cols-2 grid-cols-1 mt-4 justify-between items-start gap-4">
        <div className="card bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded mt-5">
          <div className="flex justify-between items-center mb-5">
            <p className="text-lg font-bold">Abonnement Status</p>
          </div>
          <CustomPieChart data={subscriptionData} />{" "}
        </div>
        {/* Emails Per School*/}
        <div className="card bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded mt-5">
          <div className="flex justify-between items-center mb-5">
            <p className="text-lg font-bold">E-Mail-Limit pro Schule</p>
          </div>
          <CustomPieChart data={emailPerSchool} />{" "}
        </div>
      </div>

      {/* activity graph of teacher and student */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">
            Aktivitäten von Lehrern und Schülern
          </p>
        </div>
        <DualBarGraph
          data={activityData}
          title={"teachers"}
          title2={"students"}
        />
      </div>
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Aktivitätstabelle</p>
        </div>
        <div className="h-[200px] overflow-y-auto">
          <table className="table w-[180%] md:w-full">
            <thead>
              <th className="border p-2 text-sm dark:bg-gray-600 ">Name</th>
              <th className="border p-2 text-sm dark:bg-gray-600  ">Aktion</th>
              <th className="border p-2 text-sm dark:bg-gray-600  ">Datum</th>
            </thead>
            <tbody>
              <tr>
                <td className="border p-2 text-sm text-center">Lehrer</td>
                <td className="border p-2 text-sm text-center">eingeloggt</td>

                <td className="border p-2 text-sm text-center">2024-09-02</td>
              </tr>
              <tr>
                <td className="border p-2 text-sm text-center">Lehrer</td>
                <td className="border p-2 text-sm text-center">eingeloggt</td>

                <td className="border p-2 text-sm text-center">2024-09-02</td>
              </tr>
              <tr>
                <td className="border p-2 text-sm text-center">Lehrer</td>
                <td className="border p-2 text-sm text-center">eingeloggt</td>

                <td className="border p-2 text-sm text-center">2024-09-02</td>
              </tr>
              <tr>
                <td className="border p-2 text-sm text-center">Lehrer</td>
                <td className="border p-2 text-sm text-center">eingeloggt</td>

                <td className="border p-2 text-sm text-center">2024-09-02</td>
              </tr>
              <tr>
                <td className="border p-2 text-sm text-center">Lehrer</td>
                <td className="border p-2 text-sm text-center">eingeloggt</td>

                <td className="border p-2 text-sm text-center">2024-09-02</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <SchoolEmailUsageWidget />
    </main>
  );
};
const ClassDashboard = ({ classCardData }) => {
  // ;
  const [timeFrameSentEmails, setTimeFrameSentEmails] = useState("weekly");
  const [timeFrameEmail, setTimeFrameEmail] = useState("weekly");
  const [timeFrameClass, setTimeFrameClass] = useState("weekly");
  const isToday = (date) => dayjs(date).isSame(dayjs(), "day");
  const isThisWeek = (date) => dayjs(date).isSame(dayjs(), "week");
  const isThisMonth = (date) => dayjs(date).isSame(dayjs(), "month");
  const getFilteredParticipants = (timeFrameClass) => {
    if (timeFrameClass === "daily") {
      return participationData.filter((item) => isToday(item.date));
    } else if (timeFrameClass === "weekly") {
      return participationData.filter((item) => isThisWeek(item.date));
    } else if (timeFrameClass === "monthly") {
      return participationData.filter((item) => isThisMonth(item.date));
    }
  };
  const getFilteredSentEmail = (timeFrameSentEmails) => {
    if (timeFrameSentEmails === "daily") {
      return staticDataForSentAndReceivedEmail.filter((item) =>
        isToday(item.date)
      );
    } else if (timeFrameSentEmails === "weekly") {
      return staticDataForSentAndReceivedEmail.filter((item) =>
        isThisWeek(item.date)
      );
    } else if (timeFrameSentEmails === "monthly") {
      return staticDataForSentAndReceivedEmail.filter((item) =>
        isThisMonth(item.date)
      );
    }
  };
  const getFilteredDataEmail = (timeFrameEmail) => {
    if (timeFrameEmail === "daily") {
      return staticDataForEmails.filter((item) => isToday(item.date));
    } else if (timeFrameEmail === "weekly") {
      return staticDataForEmails.filter((item) => isThisWeek(item.date));
    } else if (timeFrameEmail === "monthly") {
      return staticDataForEmails.filter((item) => isThisMonth(item.date));
    }
  };
  const usageEmails = getFilteredSentEmail(timeFrameSentEmails);
  const usageEmailData = getFilteredDataEmail(timeFrameEmail);
  const usageEmailPartcipants = getFilteredParticipants(timeFrameClass);

  const handleChangeSentEmail = (value) => {
    if (value === "daily") {
      setTimeFrameSentEmails("daily");
    } else if (value === "weekly") {
      setTimeFrameSentEmails("weekly");
    } else if (value === "monthly") {
      setTimeFrameSentEmails("monthly");
    }
  };
  const handleChangeEmail = (value) => {
    if (value === "daily") {
      setTimeFrameEmail("daily");
    } else if (value === "weekly") {
      setTimeFrameEmail("weekly");
    } else if (value === "monthly") {
      setTimeFrameEmail("monthly");
    }
  };
  const handleChangeParticipants = (value) => {
    if (value === "daily") {
      setTimeFrameClass("daily");
    } else if (value === "weekly") {
      setTimeFrameClass("weekly");
    } else if (value === "monthly") {
      setTimeFrameClass("monthly");
    }
  };
  return (
    <main>
      <div className="flex md:flex-row flex-col justify-between items-center gap-3 dark:text-white">
        <h2 className="text-xl font-bold">Dashboard</h2>
      </div>
      <div className="mt-8 mb-4 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3">
        {classCardData.map((item) => (
          <StatCard key={item.id} data={item} />
        ))}
      </div>
      <div className="w-full bg-white dark:bg-gray-700 dark:text-white px-5 py-7 rounded mt-5 ">
        <div className="flex md:flex-row flex-col justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Daten zur Klassenaktivität</p>
          <button className="btn border border-gray-300 dark:text-white dark:border-white text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3">
            <GrDocumentDownload />
            <span>Export</span>
          </button>
        </div>
        <div className="mt-14 ">
          <ClassBarChart />
        </div>
      </div>
      {/* Sent emails */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Anzahl der gesendeten E-Mails</p>
          <div className="flex justify-center space-x-4 mb-4">
            <Select
              defaultValue="daily"
              className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
              style={{ width: 200, border: 0, fontSize: "10px" }}
              onChange={handleChangeSentEmail}
              options={[
                { value: "daily", label: "täglich" },
                { value: "weekly", label: "wöchentlich" },
                { value: "monthly", label: "monatlich" },
              ]}
            />
          </div>
        </div>
        <DualBarGraph
          data={usageEmails}
          title={"sentEmails"}
          title2={"receivedEmails"}
        />
      </div>

      {/* Not Verified and Verified emails Section  */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">
            Anzahl der nicht verifizierten und verifizierten E-Mails
          </p>
          <div className="flex justify-center space-x-4 mb-4">
            <Select
              defaultValue="daily"
              className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
              style={{ width: 200, border: 0, fontSize: "10px" }}
              onChange={handleChangeEmail}
              options={[
                { value: "daily", label: "täglich" },
                { value: "weekly", label: "wöchentlich" },
                { value: "monthly", label: "monatlich" },
              ]}
            />
          </div>
        </div>
        <DualBarGraph
          data={usageEmailData}
          title={"nicht_verifiziert"}
          title2={"verifiedEmails"}
        />
      </div>
      {/* Class Participation Metrics  */}
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Kennzahlen zur Klassenbeteiligung</p>
          <div className="flex justify-center space-x-4 mb-4">
            <Select
              defaultValue="daily"
              className="border-0 p-0 text-sm dark:bg-gray-700 dark:text-white"
              style={{ width: 200, border: 0, fontSize: "10px" }}
              onChange={handleChangeParticipants}
              options={[
                { value: "daily", label: "täglich" },
                { value: "weekly", label: "wöchentlich" },
                { value: "monthly", label: "monatlich" },
              ]}
            />
          </div>
        </div>
        <DualBarGraph
          data={usageEmailPartcipants}
          title={"assignmentSubmissions"}
          title2={"attendance"}
        />
      </div>
      <div className="card bg-white dark:bg-gray-700 dark:text-white  px-5 py-7 rounded mt-5">
        <div className="flex md:flex-row flex-col mb-5 justify-between items-start gap-2 md:items-center">
          <p className="text-lg font-bold">Kennzahlen zur Klassenbeteiligung</p>
        </div>
        <div className="h-[250px] overflow-y-auto">
          <table className="table w-[180%] md:w-full">
            <thead>
              <th className="border p-2 text-sm dark:bg-gray-600 ">Name</th>
              <th className="border p-2 text-sm dark:bg-gray-600  ">
                Teilnahme
              </th>
              <th className="border p-2 text-sm dark:bg-gray-600  ">
                Aufgaben
              </th>
              <th className="border p-2 text-sm dark:bg-gray-600  ">Datum</th>
            </thead>
            <tbody>
              <tr>
                <td className="border p-2 text-sm text-center">John</td>
                <td className="border p-2 text-sm text-center">70</td>
                <td className="border p-2 text-sm text-center">20</td>
                <td className="border p-2 text-sm text-center">2024-09-02</td>
              </tr>
              <tr>
                <td className="border p-2 text-sm text-center">John</td>
                <td className="border p-2 text-sm text-center">70</td>
                <td className="border p-2 text-sm text-center">20</td>
                <td className="border p-2 text-sm text-center">2024-09-02</td>
              </tr>
              <tr>
                <td className="border p-2 text-sm text-center">John</td>
                <td className="border p-2 text-sm text-center">70</td>
                <td className="border p-2 text-sm text-center">20</td>
                <td className="border p-2 text-sm text-center">2024-09-02</td>
              </tr>
              <tr>
                <td className="border p-2 text-sm text-center">John</td>
                <td className="border p-2 text-sm text-center">70</td>
                <td className="border p-2 text-sm text-center">20</td>
                <td className="border p-2 text-sm text-center">2024-09-02</td>
              </tr>
              <tr>
                <td className="border p-2 text-sm text-center">John</td>
                <td className="border p-2 text-sm text-center">70</td>
                <td className="border p-2 text-sm text-center">20</td>
                <td className="border p-2 text-sm text-center">2024-09-02</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
};
