import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layouts/main.layout";
import Dashboard from "../pages/dashboard";
import AlleSchulen from "../pages/alle-schulen";
import SchuleHinzufugen from "../pages/schule-hinzufügen";
import DocumentenBibliotek from "../pages/dokumenten-bibliotek";
import Vereinbarungen from "../pages/vereinbarungen";
import EmailLog from "../pages/email-log";
import SystemLog from "../pages/system-log";
import LandingPage from "../pages/landing-page";
import Register from "../pages/auth/register";
import Login from "../pages/auth/login";
import ForgotPassword from "../pages/auth/forgotPassword";
import VerifyOtp from "../pages/auth/verifyOTP";
import NotFound from "../pages/errorhandling/404Page";
import Broadcaster from "../pages/broadcaster";
import ChangePassword from "../pages/auth/changePassword";
import TicketSystem from "../pages/ticket-system";
import Questions from "../pages/questions";
import Settings from "../pages/settings";
import ProtectedRoute from "./ProtectedRoute";
import ChangeLog from "../pages/change-log";
import SchoolStructure from "../pages/schule-struktur";
import UserActivity from "../pages/user-activity";
import ClassManagement from "../pages/class-management";
import TermsCondition from "../pages/auth/termsCondition";
import UploadDocuments from "../pages/uploadFiles";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    children: [],
  },
  {
    path: "/terms-and-conditions",
    element: <TermsCondition />,
    children: [],
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/verify-otp/:email",
    element: <VerifyOtp />,
    children: [
      {
        path: "2fa",
        element: <VerifyOtp />,
      },
      {
        path: "forgot-password",
        element: <VerifyOtp />,
      },
    ],
  },
  {
    path: "/",
    element: <ProtectedRoute element={<MainLayout />} />,
    children: [
      {
        path: "/dashboard/heim",
        element: <ProtectedRoute element={<Dashboard />} />,
      },
      {
        path: "/dashboard/alle-schulen",
        element: <ProtectedRoute element={<AlleSchulen />} />,
      },
      {
        path: "/dashboard/schule-hinzufugen",
        element: <ProtectedRoute element={<SchuleHinzufugen />} />,
      },
      {
        path: "/dashboard/dokumenten-bibliothek",
        element: <ProtectedRoute element={<DocumentenBibliotek />} />,
      },
      {
        path: "/dashboard/vereinbarungen",
        element: <ProtectedRoute element={<Vereinbarungen />} />,
      },
      {
        path: "/dashboard/email-log",
        element: <ProtectedRoute element={<EmailLog />} />,
      },
      {
        path: "/dashboard/system-log",
        element: <ProtectedRoute element={<SystemLog />} />,
      },
      {
        path: "/dashboard/broadcaster",
        element: <ProtectedRoute element={<Broadcaster />} />,
      },
      {
        path: "/dashboard/ticketsystem",
        element: <ProtectedRoute element={<TicketSystem />} />,
      },
      {
        path: "/dashboard/dokumenten-bibliotek",
        element: <ProtectedRoute element={<UploadDocuments />} />,
      },
      {
        path: "/dashboard/frequently-asked-questions",
        element: <ProtectedRoute element={<Questions />} />,
      },
      {
        path: "/dashboard/settings",
        element: <ProtectedRoute element={<Settings />} />,
      },
      {
        path: "/dashboard/ändern-protokoll",
        element: <ProtectedRoute element={<ChangeLog />} />,
      },
      {
        path: "/dashboard/schule-struktur",
        element: <ProtectedRoute element={<SchoolStructure />} />,
      },

      {
        path: "/dashboard/klassenleitung",
        element: <ProtectedRoute element={<ClassManagement />} />,
      },
      // {
      //   path: "/dashboard/benutzer-aktivität",
      //   element: <ProtectedRoute element={<UserActivity />} />,
      // },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);
