import React, { useContext } from "react";
import { Progress } from "antd"; // For the progress bar (if using Ant Design)
import GlobalContext from "../../context/GlobalContext";

const SchoolEmailUsageWidget = () => {
  const { dark } = useContext(GlobalContext);
  const schoolEmailUsageData = {
    schoolName: "Greenfield High School",
    totalQuota: 5000, // Total quota in emails
    emailsSent: 3500, // Emails sent so far
  };

  // Calculate the percentage of quota used
  const percentageUsed =
    (schoolEmailUsageData.emailsSent / schoolEmailUsageData.totalQuota) * 100;
  const remainingQuota =
    schoolEmailUsageData.totalQuota - schoolEmailUsageData.emailsSent;

  return (
    <div className="w-full bg-white dark:bg-gray-700  dark:text-white px-5 py-7 rounded mt-5">
      <h2 className="text-xl font-bold mb-2 text-left">
        {/* {schoolEmailUsageData.schoolName} */}
        Email Usage
      </h2>

      {/* Status Indicator */}
      <div className="flex flex-col items-center mb-4">
        <p className="mb-2 text-gray-700 dark:text-white">
          <strong>{schoolEmailUsageData.emailsSent}</strong> /{" "}
          {schoolEmailUsageData.totalQuota} emails used
        </p>

        {/* Progress Bar */}
        <Progress
          type="circle"
          percent={Math.round(percentageUsed)}
          format={(percent) => (
            <span className="text-gray-700 dark:text-white">{percent}%</span>
          )}
          width={120}
          strokeColor={dark ? "#fff" : "#374151 "}
        />
      </div>

      {/* Remaining Quota */}
      <div className="text-center text-gray-600 mt-4 dark:text-white">
        <p>
          <strong>{remainingQuota}</strong> emails remaining
        </p>
      </div>
    </div>
  );
};

export default SchoolEmailUsageWidget;
