import { DatePicker } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  FaAngleDoubleLeft,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from "date-fns";
import { IoIosClose } from "react-icons/io";
import useGetEmailLogs from "../../hooks/useGetEmailLogs";
import { ButtonLoader2 } from "../../components/buttonLoader/ButtonLoader";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
const getWeekRange = (date) => {
  const start = startOfWeek(date, { weekStartsOn: 1 });
  const end = endOfWeek(date, { weekStartsOn: 1 });
  return [start, end];
};

const getMonthRange = (date) => {
  const start = startOfMonth(date);
  const end = endOfMonth(date);
  return [start, end];
};
const EmailLog = () => {
  const [isActive, setIsActive] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dateRange, setDateRange] = useState([]);
  const { EmailLogs, loading, tableData } = useGetEmailLogs();

  const rowsPerPage = 50;

  const { RangePicker } = DatePicker;
  // const tableData = [
  //   {
  //     id: 1,
  //     event: "Fehler",
  //     user: "user@mail.de",
  //     desc: "Beschreibung",
  //     date: "2024-07-21",
  //     status: "Success",
  //     error_message: "Hallo zusammen, hiermit möchte ich euch einla..",
  //   },
  //   {
  //     id: 2,
  //     event: "Fehler",
  //     user: "user@mail.de",
  //     desc: "Beschreibung",
  //     date: "2024-07-11",
  //     status: "Success",
  //     error_message: "Hallo zusammen, hiermit möchte ich euch einla..",
  //   },
  //   {
  //     id: 3,
  //     event: "Datenaktualisierung",
  //     user: "user@mail.de",
  //     desc: "Beschreibung",
  //     date: "2024-07-10",
  //     status: "Success",
  //     error_message: "Hallo zusammen, hiermit möchte ich euch einla..",
  //   },
  //   {
  //     id: 4,
  //     event: "Datenaktualisierung",
  //     user: "user@mail.de",
  //     desc: "Beschreibung",
  //     date: "2024-07-06",
  //     status: "Success",
  //     error_message: "Hallo zusammen, hiermit möchte ich euch einla..",
  //   },
  //   {
  //     id: 5,
  //     event: "Datenaktualisierung",
  //     user: "user@mail.de",
  //     desc: "Beschreibung",
  //     date: "2024-08-26",
  //     status: "Success",
  //     error_message: "Hallo zusammen, hiermit möchte ich euch einla..",
  //   },
  //   {
  //     id: 6,
  //     event: "Datenaktualisierung",
  //     user: "user@mail.de",
  //     desc: "Beschreibung",
  //     date: "2024-08-27",
  //     status: "Success",
  //     error_message: "Hallo zusammen, hiermit möchte ich euch einla..",
  //   },
  //   {
  //     id: 7,
  //     event: "Login",
  //     user: "user@mail.de",
  //     desc: "Beschreibung",
  //     date: "2024-07-01",
  //     status: "Success",
  //     error_message: "Hallo zusammen, hiermit möchte ich euch einla..",
  //   },
  //   {
  //     id: 8,
  //     event: "Login",
  //     user: "user@mail.de",
  //     desc: "Beschreibung",
  //     date: "2024-08-21",
  //     status: "Success",
  //     error_message: "Hallo zusammen, hiermit möchte ich euch einla..",
  //   },
  //   {
  //     id: 9,
  //     event: "Login",
  //     user: "user@mail.de",
  //     desc: "Beschreibung",
  //     date: "2024-08-11",
  //     status: "Success",
  //     error_message: "Hallo zusammen, hiermit möchte ich euch einla..",
  //   },
  //   {
  //     id: 10,
  //     event: "Login",
  //     user: "user@mail.de",
  //     desc: "Beschreibung",
  //     date: "2024-08-10",
  //     status: "Success",
  //     error_message: "Hallo zusammen, hiermit möchte ich euch einla..",
  //   },
  //   {
  //     id: 11,
  //     event: "Fehler",
  //     user: "user@mail.de",
  //     desc: "Beschreibung",
  //     date: "2024-05-4",
  //     status: "Success",
  //     error_message: "Hallo zusammen, hiermit möchte ich euch einla..",
  //   },
  //   {
  //     id: 12,
  //     event: "Fehler",
  //     user: "user@mail.de",
  //     desc: "Beschreibung",
  //     date: "2024-05-2",
  //     status: "Success",
  //     error_message: "Hallo zusammen, hiermit möchte ich euch einla..",
  //   },
  //   {
  //     id: 13,
  //     event: "Fehler",
  //     user: "user@mail.de",
  //     desc: "Beschreibung",
  //     date: "2024-05-24",
  //     status: "Success",
  //     error_message: "Hallo zusammen, hiermit möchte ich euch einla..",
  //   },
  //   {
  //     id: 14,
  //     event: "Datenaktualisierung",
  //     user: "user@mail.de",
  //     desc: "Beschreibung",
  //     date: "2024-08-01",
  //     status: "Success",
  //     error_message: "Hallo zusammen, hiermit möchte ich euch einla..",
  //   },
  //   {
  //     id: 15,
  //     event: "Datenaktualisierung",

  const handleDateRangeChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setDateRange([start ? dayjs(start) : null, end ? dayjs(end) : null]);
    } else {
      setDateRange([]);
    }
  };

  const filteredQuestions = tableData?.filter((item) => {
    const itemDate = dayjs(item.sent_datetime);
    const today = dayjs();
    let isInDateRange = false;

    switch (isActive) {
      case 1: // Tag
        isInDateRange = itemDate.isSame(today, "day");
        break;
      case 2: // Woche
        const [weekStart, weekEnd] = getWeekRange(today.toDate());
        isInDateRange =
          itemDate.isSameOrAfter(dayjs(weekStart)) &&
          itemDate.isSameOrBefore(dayjs(weekEnd));
        break;
      case 3: // Monat
        const [monthStart, monthEnd] = getMonthRange(today.toDate());
        isInDateRange =
          itemDate.isSameOrAfter(dayjs(monthStart)) &&
          itemDate.isSameOrBefore(dayjs(monthEnd));
        break;
      default:
        isInDateRange = true;
    }

    return (
      item.recipient_email?.toLowerCase().includes(searchTerm.toLowerCase()) &&
      isInDateRange
    );
  });

  const totalPages = Math.ceil(filteredQuestions.length / rowsPerPage);
  const currentData = filteredQuestions.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const paginationRef = useRef(null);

  const scrollToActiveButton = (index) => {
    if (paginationRef.current) {
      const activeButton = paginationRef.current.children[index];
      if (activeButton) {
        activeButton.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  useEffect(() => {
    scrollToActiveButton(currentPage - 1);
  }, [currentPage]);

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  useEffect(() => {
    EmailLogs(isActive, dateRange);
  }, [isActive, dateRange]);
  console.log({ totalPages: totalPages });
  return (
    <main>
      <div className="flex md:flex-row flex-col justify-between items-center gap-3">
        <h2 className="text-xl font-bold dark:text-white">E-Mail Log</h2>
        <div className="flex md:flex-row flex-col justify-end items-center gap-5">
          <div className="flex justify-end items-center">
            <button
              onClick={() => setIsActive(1)}
              className={`${
                isActive === 1
                  ? "bg-blue-500 text-white border-blue-500 dark:bg-gray-700"
                  : "bg-white dark:bg-gray-600"
              } hover:bg-blue-500 hover:text-white border  border-gray-300 rounded px-3 py-1 text-sm dark:text-white`}
            >
              Tag
            </button>
            <button
              onClick={() => setIsActive(2)}
              className={`${
                isActive === 2
                  ? "bg-blue-500 text-white border-blue-500 dark:bg-gray-700 "
                  : "bg-white dark:bg-gray-600"
              } hover:bg-blue-500  hover:text-white border border-gray-300 rounded px-3 py-1 text-sm dark:text-white`}
            >
              Woche
            </button>
            <button
              onClick={() => setIsActive(3)}
              className={`${
                isActive === 3
                  ? "bg-blue-500 text-white border-blue-500  dark:bg-gray-700"
                  : "bg-white dark:bg-gray-600"
              } hover:bg-blue-500  hover:text-white border border-gray-300 rounded px-3 py-1 text-sm dark:text-white`}
            >
              Monat
            </button>
            {isActive !== 0 && (
              <IoIosClose
                className="text-[18px] text-gray-800 dark:text-white ml-1 cursor-pointer"
                onClick={() => setIsActive(0)}
              />
            )}
          </div>
          <RangePicker className="w-[200px]" onChange={handleDateRangeChange} />
        </div>
      </div>
      <div className="input-holder w-full rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center mt-5 mb-8">
        <p className="text-[12px] py-3 text-left lg:w-[200px] w-[100px] px-3 dark:text-white">
          Suchen
        </p>
        <input
          type="text"
          className="input outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-200 w-full rounded-[6px] text-[12px] lg:text-[16px]"
          placeholder="Suchen Sie nach einem bestimmten Ergebnis..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className=" bg-white dark:bg-gray-700 pb-6 mb-4 rounded-[8px]">
        <div className="overflow-x-auto">
          <table className="table w-[400%] md:w-full mb-[200px]">
            <thead>
              <th className="bg-gray-200 dark:bg-gray-600 dark:text-white px-8 text-xs py-3 text-left">
                Empfänger
              </th>
              <th className="bg-gray-200 dark:bg-gray-600 dark:text-white px-8 text-xs py-3 text-left">
                Thema
              </th>
              <th className="bg-gray-200 dark:bg-gray-600 dark:text-white px-8 text-xs py-3 text-left">
                Datum
              </th>
              <th className="bg-gray-200 dark:bg-gray-600 dark:text-white px-8 text-xs py-3 text-left">
                Status
              </th>
              <th className="bg-gray-200 dark:bg-gray-600 dark:text-white px-8 text-xs py-3 text-left">
                Error_message
              </th>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="text-center text-gray-500 py-4">
                    <ButtonLoader2 />
                  </td>
                </tr>
              ) : currentData.length > 0 ? (
                currentData.map((item) => (
                  <tr key={item.id}>
                    <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                      <Link to="mailto:user@mail.de">
                        {item.recipient_email}
                      </Link>
                    </td>
                    <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                      <Link to="mailto:user@mail.de">{item.subject}</Link>
                    </td>
                    <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                      {dayjs(item.sent_datetime).format("YYYY-MM-DD")} -{" "}
                      {dayjs(item.sent_datetime).format("HH:mm:ss")}
                    </td>
                    <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                      {item.status}
                    </td>
                    <td className="bg-white dark:bg-gray-500 dark:text-gray-100 px-8 text-xs py-3 text-left">
                      <div className="flex text-xs justify-start items-center gap-2">
                        <span>
                          {item.error_message ? item.error_message : "--"}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center text-gray-500 py-4">
                    Keine Datensätze gefunden
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {currentData.length > 0 && (
          <div className="pagination mt-5 flex justify-center items-start gap-2 w-full overflow-hidden">
            <div className="flex items-center gap-2">
              <button
                className="btn text-gray-400 px-1 py-1 rounded text-sm flex justify-center items-center "
                onClick={() => setCurrentPage(1)}
                disabled={currentPage === 1}
              >
                <FaChevronLeft />
                <FaChevronLeft />
              </button>
              <button
                className="btn text-gray-400 px-1 py-1 rounded text-sm flex justify-center items-center gap-3"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                <FaChevronLeft />
              </button>
            </div>
            <div
              className={`w-[50%] flex overflow-auto ${
                totalPages === 1 && "justify-center items-center"
              }justify-start items-start gap-2`}
              ref={paginationRef}
            >
              {[...Array(totalPages).keys()].map((page) => (
                <button
                  key={page}
                  className={`btn ${
                    currentPage === page + 1
                      ? "bg-blue-500 text-white"
                      : "text-gray-400"
                  } px-4 py-1 rounded text-sm flex justify-center items-center gap-3`}
                  onClick={() => setCurrentPage(page + 1)}
                >
                  {page + 1}
                </button>
              ))}
            </div>
            <div className="flex items-center gap-2">
              <button
                className="btn text-gray-400 px-4 py-1 rounded text-sm flex justify-center items-center gap-3"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <FaChevronRight />
              </button>
              <button
                className="btn text-gray-400 px-1 py-1 rounded text-sm flex justify-center items-center "
                onClick={() => setCurrentPage(totalPages)}
                disabled={currentPage === totalPages}
              >
                <FaChevronRight />
                <FaChevronRight />
              </button>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default EmailLog;
