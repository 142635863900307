import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { IoHomeOutline, IoStatsChartSharp } from "react-icons/io5";
import { BsBox2 } from "react-icons/bs";
import { FaRegFileAlt, FaUserCog } from "react-icons/fa";
import { HiBars3CenterLeft, HiOutlineServer } from "react-icons/hi2";
import { FiCreditCard } from "react-icons/fi";
import { IoIosArrowDown, IoIosInformationCircleOutline } from "react-icons/io";
import { TiCogOutline } from "react-icons/ti";
import { GoInbox } from "react-icons/go";
import { FaRegCircleQuestion } from "react-icons/fa6";
import {
  MdOutlineManageAccounts,
  MdOutlinePublishedWithChanges,
} from "react-icons/md";
import { PiTreeStructure } from "react-icons/pi";
import { RiSchoolLine } from "react-icons/ri";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdOutlineSystemUpdateAlt } from "react-icons/md";

const Sidebar = ({ sidebarVisible, toggleSidebar, screenWidth }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const pathsToOpenSidebar = [
      "/dashboard/alle-schulen",
      "/dashboard/schule-hinzufugen",
      "/dashboard/schule-struktur",
    ];

    if (pathsToOpenSidebar.includes(location.pathname)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location.pathname]);
  return (
    <aside
      className={`sidebar bg-white dark:bg-gray-900 md:w-[300px] overflow-y-auto w-full py-3 transition-[0.8s] pr-3 ${
        sidebarVisible ? "translate-x-0" : "translate-x-[-100%]"
      }`}
    >
      <ul className="flex flex-col gap-1">
        <li>
          <NavLink
            onClick={screenWidth < 767 && toggleSidebar}
            to="/dashboard/heim"
            className="text-gray-500 
             font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent
              hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <IoHomeOutline className="text-xl" />
            <span className="text-black dark:text-white">Dashboard</span>
          </NavLink>
        </li>

        <li>
          <div
            onClick={() => {
              if (screenWidth < 767) {
                toggleSidebar();
              }
              setOpen(!open);
            }}
            className={`text-gray-500 font-semibold p-3 
            rounded-r-md border-l-[3px]
             border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex flex-col items-start relative ${
               open && "h-[210px] bg-[#f5f8fa] dark:bg-gray-900"
             }`}
          >
            <div className="flex items-center cursor-pointer">
              <IoStatsChartSharp className="text-xl" />
              <span className="text-black dark:text-white px-3">Schulen</span>
              {open ? (
                <IoIosArrowDown className="text-sm absolute right-2 dark:text-white " />
              ) : (
                <MdKeyboardArrowRight className="text-xl absolute right-2  dark:text-white" />
              )}
            </div>
            {open && (
              <div className="w-full">
                <li>
                  <NavLink
                    onClick={screenWidth < 767 && toggleSidebar}
                    to="/dashboard/alle-schulen"
                    className="text-gray-500 mt-4 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa]
                     dark:hover:bg-gray-700 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
                  >
                    <IoStatsChartSharp className="text-xl" />
                    <span className="text-black dark:text-white">
                      Alle Schulen
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={screenWidth < 767 && toggleSidebar}
                    to="/dashboard/schule-hinzufugen"
                    className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-700 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
                  >
                    <RiSchoolLine className="text-xl" />
                    <span className="text-black dark:text-white">
                      Schule hinzufügen
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    onClick={screenWidth < 767 && toggleSidebar}
                    to="/dashboard/schule-struktur"
                    className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-700 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
                  >
                    <PiTreeStructure className="text-xl" />
                    <span className="text-black dark:text-white">
                      Schule Struktur
                    </span>
                  </NavLink>
                </li>
              </div>
            )}
          </div>
        </li>

        {/* <li>
          <NavLink
          onClick={screenWidth < 767 && toggleSidebar}
          to="/dashboard/alle-schulen"
            className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <IoStatsChartSharp className="text-xl" />
            <span className="text-black dark:text-white">Alle Schulen</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={screenWidth < 767 && toggleSidebar}
            to="/dashboard/schule-hinzufügen"
            className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <RiSchoolLine className="text-xl" />
            <span className="text-black dark:text-white">
              Schule hinzufügen
            </span>
          </NavLink>
        </li>

        <li>
          <NavLink
            onClick={screenWidth < 767 && toggleSidebar}
            to="/dashboard/schule-struktur"
            className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <PiTreeStructure className="text-xl" />
            <span className="text-black dark:text-white">Schule Struktur</span>
          </NavLink>
        </li> */}
        <li>
          <NavLink
            onClick={screenWidth < 767 && toggleSidebar}
            to="/dashboard/klassenleitung"
            className="text-gray-500 
             font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent
              hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <MdOutlineManageAccounts className="text-xl" />
            <span className="text-black dark:text-white">
              Klasse Management
            </span>
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={screenWidth < 767 && toggleSidebar}
            to="/dashboard/dokumenten-bibliotek"
            className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <BsBox2 className="text-xl" />
            <span className="text-black dark:text-white">
              Dokumenten Bibliotek
            </span>
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={screenWidth < 767 && toggleSidebar}
            to="/dashboard/vereinbarungen"
            className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <FaRegFileAlt className="text-xl" />
            <span className="text-black dark:text-white">Vereinbarungen</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={screenWidth < 767 && toggleSidebar}
            to="/dashboard/ändern-protokoll"
            className="text-gray-500 
             font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent
              hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <MdOutlinePublishedWithChanges className="text-xl" />
            <span className="text-black dark:text-white capitalize">
              ändern Protokoll
            </span>
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={screenWidth < 767 && toggleSidebar}
            to="/dashboard/email-log"
            className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <HiBars3CenterLeft className="text-xl" />
            <span className="text-black dark:text-white">E-Mail Log</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/dashboard/system-log"
            onClick={screenWidth < 767 && toggleSidebar}
            className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <MdOutlineSystemUpdateAlt className="text-xl" />
            <span className="text-black dark:text-white">System Protokoll</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/dashboard/zahlungen"
            onClick={screenWidth < 767 && toggleSidebar}
            className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <FiCreditCard className="text-xl" />
            <span className="text-black dark:text-white">Zahlungen</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/dashboard/backup"
            onClick={screenWidth < 767 && toggleSidebar}
            className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <HiOutlineServer className="text-xl" />
            <span className="text-black dark:text-white">Sicherung</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/dashboard/broadcaster"
            onClick={screenWidth < 767 && toggleSidebar}
            className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <IoIosInformationCircleOutline className="text-xl" />
            <span className="text-black dark:text-white">
              Rundfunkveranstalter
            </span>
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={screenWidth < 767 && toggleSidebar}
            to="/dashboard/ticketsystem"
            className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <GoInbox className="text-xl" />
            <span className="text-black dark:text-white">Ticketsystem</span>
          </NavLink>
        </li>

        {/* <li>
          <NavLink
            onClick={screenWidth < 767 && toggleSidebar}
            to="/dashboard/benutzer-aktivität"
            className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <FaUserCog className="text-xl" />
            <span className="text-black dark:text-white">
              Benutzer Aktivität
            </span>
          </NavLink>
        </li> */}

        <li>
          <NavLink
            onClick={screenWidth < 767 && toggleSidebar}
            to="/dashboard/frequently-asked-questions"
            className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <FaRegCircleQuestion className="text-xl" />
            <span className="text-black dark:text-white">
              Häufig gestellte Fragen
            </span>
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={screenWidth < 767 && toggleSidebar}
            to="/dashboard/settings"
            className="text-gray-500 font-semibold p-3 rounded-r-md hover:bg-[#f5f8fa] dark:hover:bg-gray-600 border-l-[3px] border-l-transparent hover:border-l-blue-500 text-xs gap-2 flex items-center"
          >
            <TiCogOutline className="text-xl" />
            <span className="text-black dark:text-white">Einstellungen</span>
          </NavLink>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
