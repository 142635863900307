import React, { useEffect, useState } from "react";
import heroImage from "../../assets/images/Dashboard.png";
import StripePricingTable from "./Stripe";
import HowItWorks from "./HowItWorks";
import Testimonials from "./Testimonials";
import Features from "./Features";
import StatisticsSection from "./Statistics";
import Footer from "./Footer";
import FontSizeChanger from "react-font-size-changer";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import DarkModeToggle from "../../components/darkmode-toggle/darkmode-toggle";
import Navbar from "./Navbar";

const LandingPage = () => {
  return (
    <div
      className="min-h-screen bg-gray-100"
      style={{ maxHeight: "100vh", overflowY: "auto" }}
    >
      {/* Navigation */}
      <Navbar />

      {/* Hero Section */}
      <div className="bg-white dark:bg-gray-800">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center py-12 md:py-24 px-8">
          <div className="flex flex-col w-full lg:w-1/2 justify-center items-start mr-4">
            <h1 className="text-3xl md:text-5xl text-gray-700 dark:text-gray-100 font-bold">
              Keine nervigen WhatsApp Gruppen mehr!
            </h1>
            <p className="text-lg text-gray-600 dark:text-gray-200 mt-4">
              Organisieren Sie ihre Klassen über einfache E-Mail Verteiler.
              Diskret und auf das Wichtigste reduziert.
            </p>
            <button className="bg-indigo-600 text-white mt-6 py-2 px-6 rounded-md hover:bg-indigo-700 transition duration-300">
              Jetzt probieren!
            </button>
          </div>
          <div className="w-full lg:w-1/2 text-center my-8 lg:my-0">
            {/* Add your product image here */}
            <img
              src={heroImage}
              alt="SchoolList Hero"
              className="rounded-xl shadow-lg"
            />
          </div>
        </div>
      </div>

      <Features />
      <StatisticsSection />
      <HowItWorks />
      <Testimonials />

      <div>
        <StripePricingTable
          pricingTableId="prctbl_1OyVd0B7VBBP9Yq15rgvSO34"
          publishableKey="pk_live_51ObMN8B7VBBP9Yq19cxwnBI3cZRmCrBOjwnPobtC44OKBs9invDzdMB1n3gVW8oNBP3CSJ3vKGUUX6ePmnLhwBwK00oTgzbwvt"
        />
      </div>
      <DarkModeToggle />
      <Footer />
    </div>
  );
};

export default LandingPage;
