import { useFormik } from "formik";
import React, { useState } from "react";
import { IoMdArrowDropright } from "react-icons/io";
import { MdArrowDropDown } from "react-icons/md";
import * as Yup from "yup";
import useBroadcasting from "../../hooks/useBroadcasting";
import { ButtonLoader2 } from "../../components/buttonLoader/ButtonLoader";
import { BiError } from "react-icons/bi";

const formSchema = Yup.object({
  subject: Yup.string().required("Betreff ist erforderlich"),
  message: Yup.string().required("Nachricht ist erforderlich"),
  empfänger: Yup.string().required("Der Empfänger ist erforderlich"),
  type: Yup.string().required("Der Benachrichtigungstyp ist erforderlich"), // New validation for type
});

const initialValues = {
  subject: "",
  message: "",
  empfänger: "",
  type: "",
};

const Broadcaster = () => {
  const [show, setShow] = useState(false);
  const [showType, setShowType] = useState(false);

  const { BroadcastingSubmit, loading } = useBroadcasting();

  const handleMenu = () => {
    setShow(!show);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      await BroadcastingSubmit({
        title: values.subject,
        message: values.message,
        recipients: values.empfänger,
        type: values.type,
      });
      resetForm();
    },
  });

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    resetForm,
  } = formik;

  const handleOptionChange = (e) => {
    const { value } = e.target;
    setFieldValue("empfänger", value); // Set the selected recipient value
  };
  const handleTypeMenu = () => {
    setShowType(!showType);
  };

  const handleTypeChange = (e) => {
    const { value } = e.target;
    setFieldValue("type", value); // Set the selected recipient value
  };

  const handleCancel = () => {
    resetForm();
  };

  return (
    <main>
      <div className="flex justify-between items-center gap-3">
        <h2 className="text-xl font-bold dark:text-white">
          Rundfunkveranstalter
        </h2>
      </div>

      <div className="card mt-6 mb-4 bg-white dark:bg-gray-700 md:p-8 p-3 rounded-[8px] h-[77vh] flex items-start justify-between flex-col">
        <div className="form mt-4 flex flex-col gap-2 w-full">
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center">
            <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
              Thema
            </p>
            <input
              type="text"
              name="subject"
              value={values.subject}
              onChange={handleChange}
              placeholder="geben sie den betreff ein..."
              className="input outline-none dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 border-none py-3 px-6 bg-gray-200 w-full rounded-[6px]"
            />
          </div>
          {errors.subject && touched.subject && (
            <div className="w-full flex items-end justify-start">
              <p className="dark:text-slate-100 text-red-700 dark:font-bold font-medium mt-1 text-xs flex items-center gap-1 justify-start">
                <BiError className="text-red-700 text-[18px]" />
                {errors.subject}
              </p>
            </div>
          )}
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 flex dark:bg-gray-600 justify-between items-center">
            <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
              Nachricht
            </p>
            <textarea
              type="text"
              name="message"
              value={values.message}
              onChange={handleChange}
              placeholder="geben sie die nachricht ein..."
              className="input outline-none border-none p-6 bg-gray-200 w-full rounded-[6px] resize-none dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300"
            />
          </div>
          {errors.message && touched.message && (
            <div className="w-full flex items-end justify-start">
              <p className="dark:text-slate-100 text-red-700 dark:font-bold font-medium mt-1 text-xs flex items-center gap-1 justify-start">
                <BiError className="text-red-700 text-[18px]" />
                {errors.message}
              </p>
            </div>
          )}
          <div className="input-holder rounded-[6px] bg-gray-100 flex justify-between items-center dark:bg-gray-600">
            <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
              Empfänger
            </p>

            <select
              name="empfänger"
              value={values.empfänger}
              onChange={handleChange}
              className="input outline-none dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 border-none py-3 px-6 bg-gray-200 w-full rounded-[6px]"
            >
              <option value="" disabled className="text-gray-500">
                empfänger auswählen...
              </option>
              <option value="Alle Benutzer">Alle Benutzer</option>
              <option value="Angegebene Rollen">Angegebene Rollen</option>
            </select>
          </div>
          {errors.empfänger && touched.empfänger && (
            <div className="w-full flex items-end justify-start">
              <p className="dark:text-slate-100 text-red-700 dark:font-bold font-medium mt-1 text-xs flex items-center gap-1 justify-start">
                <BiError className="text-red-700 text-[18px]" />
                {errors.empfänger}
              </p>
            </div>
          )}
          <div className="input-holder rounded-[6px] bg-gray-100 flex justify-between items-center dark:bg-gray-600">
            <p className="text-[12px] py-3 text-left w-[200px] px-3 dark:text-white">
              Typ
            </p>

            <select
              name="type"
              value={values.type}
              onChange={handleChange}
              className="input outline-none dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-300 border-none py-3 px-6 bg-gray-200 w-full rounded-[6px]"
            >
              <option value="" disabled className="text-gray-500">
                wählen Sie die art der übertragung aus...
              </option>
              <option value="Warnung">Warnung</option>
              <option value="Info">Info</option>
            </select>
          </div>
        </div>
        <div className="flex items-end justify-end w-full gap-4">
          <button
            type="button"
            onClick={handleCancel}
            className="mt-4 bg-gray-200 text-black hover:bg-gray-100 py-2 px-4 rounded-md transition duration-300"
          >
            Stornieren
          </button>
          <button
            onClick={handleSubmit}
            className="mt-4 bg-indigo-600 text-white py-2 px-4 h-[40px] w-[120px] flex items-center justify-center rounded-md hover:bg-indigo-700 transition duration-300"
          >
            {loading ? <ButtonLoader2 /> : "Einreichen"}
          </button>
        </div>
      </div>
    </main>
  );
};

export default Broadcaster;
