import React, { useContext, useEffect, useState, useCallback } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useGetProfile from "../hooks/useGetProfile";
import {
  ButtonLoader1,
  ButtonLoader2,
} from "../components/buttonLoader/ButtonLoader";
import GlobalContext from "../context/GlobalContext";

const ProtectedRoute = ({ element }) => {
  const { userInfo } = useContext(GlobalContext);
  const { fetchProfile } = useGetProfile();
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const location = useLocation();

  const checkAuth = useCallback(async () => {
    try {
      await fetchProfile();
      setAuthenticated(true);
    } catch (error) {
      setAuthenticated(false);
    } finally {
      setLoading(false);
    }
  }, [fetchProfile]);

  useEffect(() => {
    setLoading(true);
    checkAuth();
  }, []);

  // If not authenticated, redirect to home page
  if (!loading && !authenticated) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return (
      <>
        <div className="w-full h-screen flex dark:hidden items-center justify-center ">
          <ButtonLoader1 />
        </div>
        <div className="w-full h-screen dark:flex hidden items-center justify-center bg-gray-800">
          <ButtonLoader2 />
        </div>
      </>
    );
  }

  return element;
};

export default ProtectedRoute;
