import axios from "axios";
import { useCallback, useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import axiosInstance from "../routes/axiosInstance";

const useGetProfile = () => {
  const { setUserInfo } = useContext(GlobalContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const token = sessionStorage.getItem("token");
  const refreshToken = sessionStorage.getItem("refresh_token");
  const [error, setError] = useState(null);
  const [retried, setRetried] = useState(false);

  const refreshAccessToken = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/users/refresh-token/`, {
        refresh_token: refreshToken,
      });
      const newToken = response.data.token;
      sessionStorage.setItem("token", newToken);
      return newToken;
    } catch (err) {
      throw new Error("Failed to refresh token");
    }
  };

  const fetchProfile = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${BASE_URL}/users/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      sessionStorage.setItem("user_id", response.data.id);
      sessionStorage.setItem("user_name", response.data.first_name);
      sessionStorage.setItem("user_last_name", response.data.last_name);
      sessionStorage.setItem("user_email", response.data.email);
      setUserInfo(response.data);
      setRetried(false);
    } catch (error) {
      if (
        error.response.status === 401 ||
        (error.response.status === 500 && !retried)
      ) {
        try {
          const newToken = await refreshAccessToken();
          const response = await axios.get(`${BASE_URL}/users/profile`, {
            headers: {
              Authorization: `Bearer ${newToken}`,
              "Content-Type": "multipart/form-data",
            },
          });

          sessionStorage.setItem("user_id", response.data.id);
          sessionStorage.setItem("user_name", response.data.first_name);
          sessionStorage.setItem("user_last_name", response.data.last_name);
          sessionStorage.setItem("user_email", response.data.email);
          setUserInfo(response.data);
        } catch (refreshError) {
          window.location.href = "/";
        } finally {
          setRetried(true);
        }
      } else if (error.response && error.response.status === 500) {
        window.location.href = "/";
      } else {
        setError(error.message);
      }
    }
  }, [BASE_URL, token, refreshToken, retried, setUserInfo]);

  return { fetchProfile, error };
};

export default useGetProfile;
