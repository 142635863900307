import React, { useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useProfile from "../../hooks/useProfile";
import { BiError, BiHide, BiShow } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import { Switch } from "@headlessui/react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ButtonLoader2 } from "../../components/buttonLoader/ButtonLoader";
import useUpdatePassword from "../../hooks/useUpdatePassword";
import GlobalContext from "../../context/GlobalContext";

let EMAIL_REGX = /^\S+@\S+\.\S+$/;
const formSchema = Yup.object({
  first_name: Yup.string().required("Der Vorname ist erforderlich"),
  last_name: Yup.string().required("Nachname ist erforderlich"),
  email: Yup.string()
    .required("E-Mail ist erforderlich")
    .matches(EMAIL_REGX, "Ungültige E-Mail"),
  school: Yup.string(),
});
let Password_REGX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;
const formSchema_ForChangePassword = Yup.object({
  prevPassword: Yup.string().required("Aktuelles Passwort ist erforderlich"),
  password: Yup.string()
    .required("Passwort ist erforderlich")
    .matches(
      Password_REGX,
      "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben und mindestens eine Ziffer enthalten"
    ),
  confirm_password: Yup.string()
    .required("Zur Bestätigung ist ein Passwort erforderlich")
    .oneOf([Yup.ref("password"), null], "Passwörter müssen übereinstimmen"),
});

const Settings = () => {
  const { userInfo } = useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState("Profil Informationen");
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [previousPassword, setPreviousPassword] = useState(false);
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [enabled2FA, setEnabled2FA] = useState(false); // 2FA switch state
  const [enabled, setEnabled] = useState(false);

  const handleToggle2FA = async () => {
    setEnabled2FA(!enabled2FA);
    // You can make an API call here to save the user's 2FA preference
    // Example: await update2FASetting({ enable_2fa: !enabled2FA });
  };
  const firstName = sessionStorage.getItem("user_name");
  const lastName = sessionStorage.getItem("user_last_name");
  const Email = sessionStorage.getItem("user_email");

  const initialValues_ForProfile = {
    first_name: firstName || "",
    last_name: lastName || "",
    email: Email || "",
    school: "",
  };

  const initialValues_ForChangePassword = {
    prevPassword: "",
    password: "",
    confirm_password: "",
  };

  const { loading, ProfileManage } = useProfile();
  const { passwordLoading, PasswordManage } = useUpdatePassword();

  const formikProfile = useFormik({
    initialValues: initialValues_ForProfile,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      await ProfileManage({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        school: values.school,
      });
    },
  });

  const formikPassword = useFormik({
    initialValues: initialValues_ForChangePassword,
    validationSchema: formSchema_ForChangePassword,
    onSubmit: async (values) => {
      await PasswordManage({
        old_password: values.prevPassword,
        new_password: values.password,
      });
    },
  });

  const handleCancel = () => {
    if (activeTab === "Profil Informationen") {
      formikProfile.resetForm();
    } else if (activeTab === "Passwort Management") {
      formikPassword.resetForm();
    }
  };

  // const isFormEmpty = Object.values(values).every((value) => value === "");
  // const isSubmitDisabled = isFormEmpty || isSubmitting;
  const renderContent = () => {
    switch (activeTab) {
      case "Profil Informationen":
        return (
          <form
            className="flex flex-col items-start w-full gap-6"
            onSubmit={formikProfile.handleSubmit}
          >
            <div className="flex lg:flex-row flex-col items-center justify-between w-full lg:gap-0 gap-6">
              <div className="flex flex-col lg:w-[48%] w-[100%]">
                <div className="input-holder w-full rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center">
                  <p className="text-[12px] lg:text-[16px] py-3 text-left lg:w-[200px] w-[170px] px-3">
                    Vorname
                  </p>
                  <input
                    type="text"
                    className="input outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 w-full rounded-[6px] text-[12px] lg:text-[16px]"
                    name="first_name"
                    value={formikProfile.values.first_name}
                    onChange={formikProfile.handleChange}
                  />
                </div>
                {formikProfile.errors.first_name &&
                  formikProfile.touched.first_name && (
                    <p className="dark:text-slate-100 text-red-700 dark:font-bold font-medium mt-1 text-xs flex items-center gap-1 justify-start">
                      <BiError className="text-red-700 text-[18px]" />{" "}
                      {formikProfile.errors.first_name}
                    </p>
                  )}
              </div>

              <div className="flex flex-col lg:w-[48%] w-[100%]">
                <div className="input-holder rounded-[6px] w-[100%] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center">
                  <p className="text-[12px] lg:text-[16px] py-3 text-left lg:w-[200px] w-[170px] px-3">
                    Nachname
                  </p>
                  <input
                    type="text"
                    className="input outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 w-full rounded-[6px] text-[12px] lg:text-[16px]"
                    name="last_name"
                    value={formikProfile.values.last_name}
                    onChange={formikProfile.handleChange}
                  />
                </div>
                {formikProfile.errors.last_name &&
                  formikProfile.touched.last_name && (
                    <p className="dark:text-slate-100 text-red-700 dark:font-bold font-medium mt-1 text-xs flex items-center gap-1 justify-start">
                      <BiError className="text-red-700 text-[18px]" />{" "}
                      {formikProfile.errors.last_name}
                    </p>
                  )}
              </div>
            </div>

            <div className="flex flex-col w-[100%]">
              <div className="input-holder rounded-[6px] w-[100%] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center">
                <p className="text-[12px] lg:text-[16px] py-3 text-left w-[170px] px-3">
                  E-Mail
                </p>
                <input
                  type="text"
                  className="input outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 w-full rounded-[6px] text-[12px] lg:text-[16px]"
                  name="email"
                  value={formikProfile.values.email}
                  onChange={formikProfile.handleChange}
                />
              </div>
              {formikProfile.errors.email && formikProfile.touched.email && (
                <p className="text-red-700 dark:text-gray-200 text-xs mt-1 text-right">
                  {formikProfile.errors.email}
                </p>
              )}
            </div>

            <div className="input-holder rounded-[6px] w-[100%] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center">
              <p className="text-[12px] lg:text-[16px] py-3 text-left w-[170px] px-3">
                Schule
              </p>
              <input
                type="text"
                className="input outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 w-full rounded-[6px] text-[12px] lg:text-[16px]"
                name="school"
                value={formikProfile.values.school}
                // onChange={formikProfile.handleChange}
                disabled
              />
            </div>
            <div className="flex items-center justify-between w-full">
              <p className="text-[12px] lg:text-[16px] py-3 dark:text-white">
                Two-Factor Authentication (2FA)
              </p>
              <Switch
                checked={enabled2FA}
                onChange={handleToggle2FA}
                className={`${
                  enabled2FA ? "bg-indigo-600" : "bg-gray-300"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">
                  Enable Two-Factor Authentication
                </span>
                <span
                  className={`${
                    enabled2FA ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
            <div className="flex items-center justify-end gap-4 mt-2 w-full">
              <button
                type="button"
                onClick={handleCancel}
                className="bg-gray-200 text-black hover:bg-gray-100 py-2 px-4 rounded-md transition duration-300 text-[12px] lg:text-[16px]"
              >
                Stornieren
              </button>
              <button
                type="submit"
                className={`py-2 px-4 rounded-md transition duration-300 text-[12px] h-[40px] w-[120px] flex items-center justify-center lg:text-[16px] ${
                  formikProfile.isSubmitting || !formikProfile.isValid
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-indigo-600 text-white hover:bg-indigo-700"
                }`}
                disabled={formikProfile.isSubmitting || !formikProfile.isValid}
              >
                {loading ? <ButtonLoader2 /> : "Einreichen"}
              </button>
            </div>
          </form>
        );
      case "Passwort Management":
        return (
          <form
            className="flex flex-col items-start w-full gap-6"
            onSubmit={formikPassword.handleSubmit}
          >
            <div className="flex flex-col w-full">
              <div className="input-holder w-full rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center relative">
                <p className="text-[12px] lg:text-[16px]  text-left lg:w-[200px] w-[170px] px-3">
                  Aktuelles Passwort
                </p>
                <input
                  type={previousPassword ? "text" : "password"}
                  className="input outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 w-full rounded-[6px] text-[12px] lg:text-[16px]"
                  name="prevPassword"
                  value={formikPassword.values.prevPassword}
                  onChange={formikPassword.handleChange}
                />
                <span
                  className="cursor-pointer text-xl absolute right-2 text-gray-500 dark:text-white"
                  onClick={() => setPreviousPassword(!previousPassword)}
                >
                  {previousPassword ? <BiShow /> : <BiHide />}
                </span>
              </div>
              {formikPassword.errors.prevPassword &&
                formikPassword.touched.prevPassword && (
                  <p className="text-red-700 dark:text-gray-200 text-xs mt-1 text-left">
                    {formikPassword.errors.prevPassword}
                  </p>
                )}
            </div>

            <div className="flex flex-col w-full">
              <div className="input-holder w-full rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center relative">
                <p className="text-[12px] lg:text-[16px] text-left lg:w-[200px] w-[170px] px-3">
                  Neues Passwort
                </p>
                <input
                  type={password ? "text" : "password"}
                  className="input outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 w-full rounded-[6px] text-[12px] lg:text-[16px]"
                  name="password"
                  value={formikPassword.values.password}
                  onChange={formikPassword.handleChange}
                />
                <span
                  className="cursor-pointer text-xl absolute right-2 text-gray-500  dark:text-white"
                  onClick={() => setPassword(!password)}
                >
                  {password ? <BiShow /> : <BiHide />}
                </span>
              </div>
              {formikPassword.errors.password &&
                formikPassword.touched.password && (
                  <p className="text-red-700 dark:text-gray-200 text-xs mt-1 text-left">
                    {formikPassword.errors.password}
                  </p>
                )}
            </div>

            <div className="flex flex-col w-full">
              <div className="input-holder w-full rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 dark:text-white flex justify-between items-center relative">
                <p className="text-[12px] lg:text-[16px] text-left lg:w-[200px] w-[170px] px-3">
                  Passwort bestätigen
                </p>
                <input
                  type={confirmPassword ? "text" : "password"}
                  className="input outline-none border-none p-[13px] bg-gray-200 dark:bg-gray-500 w-full rounded-[6px] text-[12px] lg:text-[16px]"
                  name="confirm_password"
                  value={formikPassword.values.confirm_password}
                  onChange={formikPassword.handleChange}
                />
                <span
                  className="cursor-pointer text-xl absolute right-2 text-gray-500 dark:text-white"
                  onClick={() => setConfirmPassword(!confirmPassword)}
                >
                  {confirmPassword ? <BiShow /> : <BiHide />}
                </span>
              </div>
              {formikPassword.errors.confirm_password &&
                formikPassword.touched.confirm_password && (
                  <p className="text-red-700 dark:text-gray-200 text-xs mt-1 text-left">
                    {formikPassword.errors.confirm_password}
                  </p>
                )}
            </div>

            <div className="flex items-center justify-end gap-4 mt-2 w-full">
              <button
                type="button"
                onClick={handleCancel}
                className="bg-gray-200 text-black hover:bg-gray-100 py-2 px-4 rounded-md transition duration-300 text-[12px] lg:text-[16px]"
              >
                Stornieren
              </button>
              <button
                type="submit"
                className={`py-2 px-4 rounded-md transition duration-300 text-[12px] h-[40px] w-[120px] flex items-center justify-center lg:text-[16px] ${
                  formikPassword.isSubmitting || !formikPassword.isValid
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-indigo-600 text-white hover:bg-indigo-700"
                }`}
                disabled={
                  formikPassword.isSubmitting || !formikPassword.isValid
                }
              >
                {passwordLoading ? <ButtonLoader2 /> : "Einreichen"}
              </button>
            </div>
          </form>
        );
      case "Konto Einstellungen":
        return (
          <div className="flex flex-col items-start gap-6">
            <div className="flex items-center w-full justify-between dark:text-white">
              <p className="lg:text-[16px] text-[12px] ">
                Benachrichtigungen ein-/ausschalten
              </p>
              <Switch
                checked={enabled}
                onChange={setEnabled}
                className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-blue-600"
              >
                <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6 text-[12px] lg:text-[16px]" />
              </Switch>
            </div>
            {/*  */}
            <div className="flex items-center w-full justify-between dark:text-white">
              <p className="lg:text-[16px] text-[12px] ">Sprache ändern</p>
              <Menu>
                <MenuButton>
                  <div className="bg-gray-200 text-black hover:bg-gray-100 py-2 px-4 rounded-md transition duration-300 flex items-center justify-center gap-5">
                    <p className="text-[12px] lg:text-[16px]">
                      {selectedLanguage}
                    </p>
                    <IoIosArrowForward />
                  </div>
                </MenuButton>
                <MenuItems
                  anchor="bottom"
                  className="bg-gray-100 w-[120px] py-4 px-3 shadow-md cursor-pointer"
                >
                  <MenuItem>
                    <a
                      className="block data-[focus]:bg-blue-100 py-3 px-3 rounded-lg cursor-pointer"
                      onClick={() => setSelectedLanguage("English")}
                    >
                      Englisch
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      className="block data-[focus]:bg-blue-100 py-3 px-3 rounded-lg cursor-pointer"
                      onClick={() => setSelectedLanguage("German")}
                    >
                      German
                    </a>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <main>
      <div className="flex justify-between items-center gap-3 dark:text-white">
        {/* Frequently asked questions */}
        <div className="flex items-center">
          <h2 className="text-xl font-bold">Einstellungen</h2>
          <IoIosArrowForward />
          <p className="text-lg font-medium">{activeTab}</p>
        </div>
      </div>

      <div className="card mt-6 mb-4 bg-white dark:bg-gray-700 md:p-8 p-3 rounded-[8px]">
        <div className="p-4">
          <div className="flex justify-around w-full">
            {[
              "Profil Informationen",
              "Passwort Management",
              "Konto Einstellungen",
            ].map((category) => (
              <div
                key={category}
                onClick={() => setActiveTab(category)}
                className={`cursor-pointer p-2 px-4 text-sm font-semibold w-[33%] text-center flex items-center justify-center lg:text-[16px] text-[11px]  ${
                  activeTab === category
                    ? "text-indigo-700 bg-gray-200 dark:bg-gray-600 dark:text-white"
                    : "text-gray-400 bg-gray-100 hover:bg-slate-100 dark:bg-gray-500 dark:hover:text-gray-200 hover:text-indigo-500"
                }`}
              >
                {category}
              </div>
            ))}
          </div>
          <div className="mt-16">{renderContent()}</div>
        </div>
      </div>
    </main>
  );
};

export default Settings;
