import React, { useState, useEffect, useMemo } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { Select } from "antd"; // Import the Select component from antd
import useEditTicket from "../../hooks/useEditTicket";
import { ButtonLoader2 } from "../buttonLoader/ButtonLoader";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";

const EditModel = ({ setShowEditModel, detail }) => {
  const [subject, setSubject] = useState(detail?.title);
  const [dated, setDated] = useState(detail?.date);
  const [files, setFiles] = useState([{ name: detail?.file }]);
  const { EditingTicket, loading } = useEditTicket();
  console.log({ detail });
  useEffect(() => {
    setSubject(detail?.title);
    setDated(detail?.dated);
  }, [detail]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("submitted");
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setShowEditModel(false);
  };

  const updateStatus = (value) => {
    setDated(value);
  };
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: "*/*", // Accept all file types
      multiple: false,
      onDrop: (acceptedFiles) => {
        const filteredFiles = acceptedFiles.filter((file) => {
          const miscExtensions = ["exe", "bat", "sh", "cmd"]; // Miscellaneous/unwanted file types
          const fileExtension = file.name.split(".").pop().toLowerCase();
          return !miscExtensions.includes(fileExtension);
        });
        setFiles(filteredFiles); // Set files only if they pass the filter
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  return (
    <div className="w-screen h-screen bg-[#8383838c] flex items-center justify-center overflow-none z-50 fixed top-0 left-0">
      <div className="w-[90%] md:w-[60%] py-5 px-5 bg-white relative dark:bg-gray-800 dark:text-white ">
        <IoIosCloseCircle
          className="absolute -right-3 -top-2 text-2xl bg-white dark:bg-gray-800 rounded-full cursor-pointer"
          onClick={() => setShowEditModel(false)}
        />
        <h2 className="text-xl font-bold py-4">Dokumentieren Details</h2>
        <form className="form mt-4 flex flex-col gap-2" onSubmit={handleSubmit}>
          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-500  flex justify-between items-center">
            <p className="text-[12px] lg:text-[16px] py-3 text-left w-[200px] px-3">
              Titel
            </p>
            <input
              type="text"
              className="input outline-none border-none p-3 bg-gray-200 w-full dark:bg-gray-600  rounded-[6px] text-[12px] lg:text-[16px]"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>

          <div className="input-holder rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-500  flex justify-between items-center">
            <p className="py-3 text-left w-[200px] px-3 text-[12px] lg:text-[16px]">
              Datei
            </p>
            <div className="container bg-gray-200 dark:bg-gray-600 dark:text-white text-gray-400">
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <div className="flex flex-col items-center justify-center mt-3">
                  <p className="lg:text-[16px] text-[12px]">
                    Ziehen Sie einige PNG-, JPG-, JPEG- und PDF-Dateien per Drag
                    & Drop
                  </p>
                  <p className="lg:text-[16px]  text-[12px]">or</p>
                  <button
                    type="button"
                    className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300"
                  >
                    Wählen
                  </button>
                </div>
              </div>
            </div>
          </div>
          {files.length ? (
            files?.map((file, index) => (
              <div
                key={index}
                className="text-gray-700 text-sm dark:text-white w-full flex items-end justify-endnpm"
              >
                {file?.name}
              </div>
            ))
          ) : (
            <div className="text-gray-700 text-sm dark:text-white w-full flex items-end justify-end">
              Keine Datei hochgeladen
            </div>
          )}

          <div className="flex items-end justify-end w-full gap-4">
            <button
              type="button"
              onClick={handleCancel}
              className="mt-4 bg-gray-200 text-black hover:bg-gray-100 py-2 px-4 rounded-md transition duration-300 text-[12px] lg:text-[16px]"
            >
              Stornieren
            </button>
            <button
              type="submit"
              className="mt-4 bg-indigo-600 text-white py-2 px-6 rounded-md hover:bg-indigo-700 transition duration-300 text-[12px] lg:text-[16px] h-[40px] w-[120px] flex items-center justify-center"
            >
              {loading ? <ButtonLoader2 /> : "Einreichen"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditModel;
