import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BiError, BiHide, BiShow } from "react-icons/bi";
import useRegister from "../../../hooks/useRegister";
import FloatingInput from "../../../components/input/FloatingInput";
import { ButtonLoader2 } from "../../../components/buttonLoader/ButtonLoader";
import Background from "../../../assets/images/background.png";

let EMAIL_REGX = /^\S+@\S+\.\S+$/;
let Password_REGX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{3,32}$/;
const formSchema = Yup.object({
  first_name: Yup.string().required("Der Vorname ist erforderlich"),
  last_name: Yup.string().required("Nachname ist erforderlich"),
  email: Yup.string()
    .required("E-Mail ist erforderlich")
    .matches(EMAIL_REGX, "Ungültige E-Mail"),
  password: Yup.string()
    .required("Passwort ist erforderlich")
    .matches(
      Password_REGX,
      "Das Passwort besteht aus mindestens einem Großbuchstaben, einem Kleinbuchstaben und mindestens einer Ziffer"
    ),
  confirm_password: Yup.string()
    .required("Zur Bestätigung ist ein Passwort erforderlich")
    .oneOf([Yup.ref("password"), null], "Passwörter müssen übereinstimmen"),

  agb: Yup.boolean().oneOf(
    [true],
    "Bitte akzeptieren Sie die Nutzungsbedingungen und Datenschutzbestimmungen, bevor Sie beginnen!"
  ),
  newsletter: Yup.boolean(),
  school: Yup.string(),
});
const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirm_password: "",
  agb: false,
  newsletter: false,
  school: "",
};
const Register = () => {
  return (
    <>
      <div
        className=" flex items-center justify-center w-full h-screen overflow-auto py-5 px-4"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full flex md:w-[45%] items-start 3xl:items-center justify-start h-full">
          <RegisterInFrom />
        </div>
      </div>
    </>
  );
};

export default Register;

function RegisterInFrom() {
  const { loading, userRegister } = useRegister();
  const Navigate = useNavigate();
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      await userRegister({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
        school: values.school,
      });
    },
  });

  const goToSignIn = () => {
    Navigate("/login");
  };

  return (
    <div
      className="flex w-full flex-col justify-start items-center 
       box-border 2xl:px-[100px] lg:px-12 xs:px-4 border-solid  py-5 overflow-auto
    p-8 rounded-lg shadow-xl border border-indigo-500 shadow-indigo-600 max-h-screen-md max-w-screen-md bg-white/30 backdrop-blur-lg"
    >
      <h2 className="text-3xl font-bold mb-6 text-center text-slate-100">
        Willkommen!
      </h2>
      <p className="text-lg text-center text-slate-100">
        Registrieren Sie sich, um die E-Mails Ihrer Klasse zu verwalten
      </p>

      <div className="w-full flex flex-col lg:mt-4 xs:mt-4">
        <div className="w-full">
          <FloatingInput
            label="Vorname"
            name="first_name"
            value={values.first_name}
            onChange={handleChange}
            className="w-full"
          />
          {errors.first_name && touched.first_name && (
            <p className="text-slate-100 font-bold mt-1 text-xs flex items-center gap-1 justify-end">
              <BiError className="text-red-700 text-[16px]" />
              {errors.first_name}
            </p>
          )}
        </div>
        <div className="w-full lg:mt-4 xs:mt-2">
          <FloatingInput
            label="Nachname"
            name="last_name"
            value={values.last_name}
            onChange={handleChange}
            className="w-full"
          />
          {errors.last_name && touched.last_name && (
            <p className="text-slate-100 font-bold mt-1 text-xs flex items-center gap-1 justify-end">
              <BiError className="text-red-700 text-[16px]" />{" "}
              {errors.last_name}
            </p>
          )}
        </div>
        <div className="w-full lg:mt-4 xs:mt-2">
          <FloatingInput
            label="E-Mail"
            name="email"
            value={values.email}
            onChange={handleChange}
            className="w-full"
          />
          {errors.email && touched.email && (
            <p className="text-slate-100 font-bold mt-1 text-xs flex items-center gap-1 justify-end">
              <BiError className="text-red-700 text-[16px]" /> {errors.email}
            </p>
          )}
        </div>

        <div className="w-full relative lg:mt-4 xs:mt-2">
          <FloatingInput
            className="w-full"
            label=" Passwort"
            name="password"
            value={values.password}
            onChange={handleChange}
            type={password ? "text" : "password"}
          />
          <div
            className="h-full absolute top-4 right-2 cursor-pointer flex items-center justify-center text-center"
            onClick={() => setPassword(!password)}
          >
            {password ? (
              <BiShow className="text-2xl text-[#95969D]" />
            ) : (
              <BiHide className="text-2xl text-[#95969D]" />
            )}
          </div>
        </div>
        {errors.password && touched.password && (
          <p className="text-slate-100 font-bold mt-1 text-xs flex items-center gap-1 justify-end">
            <BiError className="text-red-700 text-[16px]" /> {errors.password}
          </p>
        )}

        <div className="w-full relative lg:mt-4 xs:mt-2">
          <FloatingInput
            className="w-[100%]"
            label="Passwort bestätigen"
            name="confirm_password"
            value={values.confirm_password}
            onChange={handleChange}
            type={confirmPassword ? "text" : "password"}
          />

          <div
            className="h-full absolute top-4 right-2 cursor-pointer flex items-center justify-center text-center"
            onClick={() => {
              setConfirmPassword(!confirmPassword);
            }}
          >
            {confirmPassword ? (
              <BiShow className="text-2xl text-[#95969D]" />
            ) : (
              <BiHide className="text-2xl text-[#95969D]" />
            )}
          </div>
        </div>
        {errors.confirm_password && touched.confirm_password && (
          <p className="text-slate-100 font-bold mt-1 text-xs flex items-center gap-1 justify-end">
            <BiError className="text-red-700 text-[16px]" />{" "}
            {errors.confirm_password}
          </p>
        )}

        <div className="w-full lg:mt-4 xs:mt-2">
          <FloatingInput
            label="Schule"
            name="school"
            type="text"
            value={values.school}
            onChange={handleChange}
            className="w-full"
          />
        </div>
        <div className="w-full flex flex-col lg:mt-2 xs:mt-2">
          <div className="flex gap-[4px] flex-col items-start">
            <div className="flex gap-2 mt-4">
              <input
                type="checkbox"
                name="agb"
                checked={values.agb}
                onChange={handleChange}
              />
              <Link
                to="/terms-and-conditions"
                className="text-xs text-white underline"
              >
                Ich habe die AGB gelesen und stimme zu
              </Link>
            </div>
            {errors.agb && touched.agb && (
              <p className="text-slate-100 font-bold mt-1 text-xs flex items-center gap-1 justify-end">
                <BiError className="text-red-700 text-[16px]" /> {errors.agb}
              </p>
            )}

            <div className="flex gap-2 mt-2">
              <input
                type="checkbox"
                name="newsletter"
                checked={values.newsletter}
                onChange={handleChange}
              />
              <span className="text-xs text-white">
                Newsletter abonnieren (optional)
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between w-full lg:mt-4 xs:mt-4">
          <button
            className="bg-themeColor rounded-[10px] w-[100%] bg-indigo-600  hover:bg-indigo-700
             h-[50px] text-gray-100 font-[600] flex justify-center items-center"
            onClick={handleSubmit}
          >
            {loading ? <ButtonLoader2 /> : "Registrieren"}
          </button>
        </div>
      </div>

      <div className="flex flex-row justify-center items-center w-full mt-4">
        <div className="bg-gray-100 w-[45%] h-[1px]"></div>
        <div className="box-border border rounded-md w-[16%] md:w-[12%] text-center text-gray-100">
          oder
        </div>
        <div className="bg-gray-100 w-[45%] h-[1px]"></div>
      </div>
      <div className="flex items-center">
        <span className=" text-gray-100 text-[16px] xs:text-[14px] 2xl:py-4 lg:py-4 mdl:py-4 xs:py-4 ">
          Habe bereits ein Konto ?
        </span>
        <button
          className="mx-[4px] text-[17px] font-bold text-indigo-600"
          onClick={goToSignIn}
        >
          Login
        </button>
      </div>
    </div>
  );
}
