const data = [
  {
    name: "Jan 23",
    Schule: 33,
    Klassen: 22,
    Registrierte: 10,
  },
  {
    name: "Feb 23",
    Schule: 15,
    Klassen: 35,
    Registrierte: 30,
  },
  {
    name: "Mar 23",
    Schule: 21,
    Klassen: 30,
    Registrierte: 28,
  },
  {
    name: "Apr 23",
    Schule: 28,
    Klassen: 25,
    Registrierte: 23,
  },
  {
    name: "May 23",
    Schule: 20,
    Klassen: 55,
    Registrierte: 36,
  },
  {
    name: "Jun 23",
    Schule: 25,
    Klassen: 23,
    Registrierte: 44,
  },
  {
    name: "Jul 23",
    Schule: 30,
    Klassen: 20,
    Registrierte: 48,
  },
  {
    name: "Aug 23",
    Schule: 38,
    Klassen: 28,
    Registrierte: 15,
  },
  {
    name: "Sep 23",
    Schule: 40,
    Klassen: 25,
    Registrierte: 34,
  },
  {
    name: "Oct 23",
    Schule: 15,
    Klassen: 18,
    Registrierte: 21,
  },
  {
    name: "Nov 23",
    Schule: 32,
    Klassen: 36,
    Registrierte: 44,
  },
  {
    name: "Dec 23",
    Schule: 3,
    Klassen: 42,
    Registrierte: 25,
  },
];
const dataSupportTicket = [
  {
    name: "Jan 23",
    open: 33,
    resolved: 22,
    pending: 10,
  },
  {
    name: "Feb 23",
    open: 15,
    resolved: 35,
    pending: 30,
  },
  {
    name: "Mar 23",
    open: 21,
    resolved: 30,
    pending: 28,
  },
  {
    name: "Apr 23",
    open: 28,
    resolved: 25,
    pending: 23,
  },
  {
    name: "May 23",
    open: 20,
    resolved: 55,
    pending: 36,
  },
];
const dataEmails = [
  {
    name: "Jan",
    Emails: 500,
  },
  {
    name: "Feb",
    Emails: 400,
  },
  {
    name: "Mar",
    Emails: 200,
  },
  {
    name: "Apr",
    Emails: 400,
  },
  {
    name: "May",
    Emails: 500,
  },
  {
    name: "Jun",
    Emails: 450,
  },
  {
    name: "Jul",
    Emails: 430,
  },
  {
    name: "Aug",
    Emails: 500,
  },
  {
    name: "Sep",
    Emails: 440,
  },
  {
    name: "Oct",
    Emails: 450,
  },
  {
    name: "Nov",
    Emails: 410,
  },
  {
    name: "Dec",
    Emails: 450,
  },
];
// Static data for different time frames
const staticData = [
  { date: "2024-10-08", sent: 600 },
  { date: "2024-10-07", sent: 600 },
  { date: "2024-09-25", sent: 700 },
  { date: "2024-09-27", sent: 800 },
  { date: "2024-09-28", sent: 700 },
  { date: "2024-09-29", sent: 800 },
  { date: "2024-09-22", sent: 700 },
  { date: "2024-09-21", sent: 800 },
  { date: "2024-08-24", sent: 600 },
  { date: "2024-05-25", sent: 700 },
  { date: "2024-03-26", sent: 800 },
];
const staticDataForRegisteredClasses = [
  { date: "2024-10-08", angemeldete_Klassen: 600 },
  { date: "2024-10-07", angemeldete_Klassen: 600 },
  { date: "2024-09-25", angemeldete_Klassen: 700 },
  { date: "2024-09-27", angemeldete_Klassen: 800 },
  { date: "2024-09-28", angemeldete_Klassen: 700 },
  { date: "2024-09-29", angemeldete_Klassen: 800 },
  { date: "2024-09-22", angemeldete_Klassen: 700 },
  { date: "2024-09-21", angemeldete_Klassen: 800 },
  { date: "2024-08-24", angemeldete_Klassen: 600 },
  { date: "2024-05-25", angemeldete_Klassen: 700 },
  { date: "2024-03-26", angemeldete_Klassen: 800 },
];
const incomeData = [
  { month: "January", monthlyIncome: 5000, totalIncome: 5000 },
  { month: "February", monthlyIncome: 7000, totalIncome: 12000 },
  { month: "March", monthlyIncome: 6000, totalIncome: 18000 },
  { month: "April", monthlyIncome: 8000, totalIncome: 26000 },
  { month: "May", monthlyIncome: 7500, totalIncome: 33500 },
  { month: "June", monthlyIncome: 9000, totalIncome: 42500 },
  { month: "July", monthlyIncome: 8500, totalIncome: 51000 },
  { month: "August", monthlyIncome: 9500, totalIncome: 60500 },
  { month: "September", monthlyIncome: 10000, totalIncome: 70500 },
  { month: "October", monthlyIncome: 11000, totalIncome: 81500 },
  { month: "November", monthlyIncome: 12000, totalIncome: 93500 },
  { month: "December", monthlyIncome: 13000, totalIncome: 106500 },
];
const staticDataSchool = [
  { date: "2024-10-07", neueSchulen: 600 },
  { date: "2024-10-08", neueSchulen: 600 },

  { date: "2024-09-25", neueSchulen: 700 },
  { date: "2024-09-27", neueSchulen: 800 },
  { date: "2024-09-28", neueSchulen: 700 },
  { date: "2024-09-29", neueSchulen: 800 },
  { date: "2024-09-22", neueSchulen: 700 },
  { date: "2024-09-21", neueSchulen: 800 },
  { date: "2024-08-24", neueSchulen: 600 },
  { date: "2024-05-25", neueSchulen: 700 },
  { date: "2024-03-26", neueSchulen: 800 },
];

const staticDataForSentEmail = [
  { date: "2024-10-08", sent: 600 },
  { date: "2024-10-02", sent: 700 },
  { date: "2024-10-07", sent: 800 },
  { date: "2024-10-28", sent: 700 },
  { date: "2024-10-29", sent: 800 },
  { date: "2024-10-22", sent: 700 },
  { date: "2024-10-21", sent: 800 },
  { date: "2024-10-24", sent: 600 },
  { date: "2024-10-25", sent: 700 },
  { date: "2024-10-26", sent: 800 },
];

const staticDataEmail = [
  // { date: "2024-09-30", nicht_verifiziert: 600 },
  { date: "2024-10-1", nicht_verifiziert: 600 },
  { date: "2024-10-2", nicht_verifiziert: 700 },
  { date: "2024-10-07", nicht_verifiziert: 800 },
  { date: "2024-10-28", nicht_verifiziert: 700 },
  { date: "2024-10-29", nicht_verifiziert: 800 },
  { date: "2024-10-22", nicht_verifiziert: 700 },
  { date: "2024-10-21", nicht_verifiziert: 800 },
  { date: "2024-10-24", nicht_verifiziert: 600 },
  { date: "2024-10-25", nicht_verifiziert: 700 },
  { date: "2024-10-26", nicht_verifiziert: 800 },
];
const staticDataSupprtTicket = [
  { date: "2024-10-07", support_tickets: 600 },
  { date: "2024-10-08", support_tickets: 600 },
  { date: "2024-09-25", support_tickets: 700 },
  { date: "2024-09-27", support_tickets: 800 },
  { date: "2024-09-28", support_tickets: 700 },
  { date: "2024-09-29", support_tickets: 800 },
  { date: "2024-09-22", support_tickets: 700 },
  { date: "2024-09-21", support_tickets: 800 },
  { date: "2024-08-24", support_tickets: 600 },
  { date: "2024-05-25", support_tickets: 700 },
  { date: "2024-03-26", support_tickets: 800 },
];
// Sample data for registered schools
const schoolData = [
  { month: "January", newRegistrations: 5, totalRegistrations: 5 },
  { month: "February", newRegistrations: 10, totalRegistrations: 15 },
  { month: "March", newRegistrations: 7, totalRegistrations: 22 },
  { month: "April", newRegistrations: 12, totalRegistrations: 34 },
  { month: "May", newRegistrations: 15, totalRegistrations: 49 },
  { month: "June", newRegistrations: 8, totalRegistrations: 57 },
  { month: "July", newRegistrations: 14, totalRegistrations: 71 },
  { month: "August", newRegistrations: 20, totalRegistrations: 91 },
  { month: "September", newRegistrations: 18, totalRegistrations: 109 },
  { month: "October", newRegistrations: 22, totalRegistrations: 131 },
  { month: "November", newRegistrations: 17, totalRegistrations: 148 },
  { month: "December", newRegistrations: 25, totalRegistrations: 173 },
];
// Sample data for email slots (static for now, replace with dynamic data if needed)
const emailSlotData = [
  { month: "January", newSlots: 50, totalSlots: 150 },
  { month: "February", newSlots: 80, totalSlots: 230 },
  { month: "March", newSlots: 100, totalSlots: 330 },
  { month: "April", newSlots: 70, totalSlots: 400 },
  { month: "May", newSlots: 90, totalSlots: 490 },
  { month: "June", newSlots: 110, totalSlots: 600 },
  { month: "July", newSlots: 60, totalSlots: 660 },
  { month: "August", newSlots: 120, totalSlots: 780 },
  { month: "September", newSlots: 90, totalSlots: 870 },
  { month: "October", newSlots: 130, totalSlots: 1000 },
  { month: "November", newSlots: 80, totalSlots: 1080 },
  { month: "December", newSlots: 100, totalSlots: 1180 },
];
// subs status data
const subscriptionData = [
  { name: "Active", value: 400 },
  { name: "Expired", value: 100 },
  { name: "Upcoming Renewal", value: 50 },
];
// subs status data
const emailPerSchool = [
  { name: "Total", value: 400 },
  { name: "Used", value: 100 },
];
// teacher and student acitvity
const activityData = [
  { date: "2024-09-01", teachers: 20, students: 0 },
  { date: "2024-10-07", teachers: 22, students: 45 },
  { date: "2024-10-04", teachers: 2, students: 78 },
  { date: "2024-10-5", teachers: 22, students: 86 },
  { date: "2024-10-2", teachers: 20, students: 25 },
  { date: "2024-10-20", teachers: 12, students: 58 },
  { date: "2024-10-12", teachers: 25, students: 10 },
  { date: "2024-10-22", teachers: 28, students: 15 },
];
// number of verified and not verified emails
const staticDataForEmails = [
  { date: "2024-10-08", nicht_verifiziert: 60, verifiedEmails: 50 },
  { date: "2024-10-02", nicht_verifiziert: 70, verifiedEmails: 30 },
  { date: "2024-10-07", nicht_verifiziert: 80, verifiedEmails: 20 },
  { date: "2024-10-28", nicht_verifiziert: 70, verifiedEmails: 10 },
  { date: "2024-10-29", nicht_verifiziert: 80, verifiedEmails: 60 },
  { date: "2024-10-22", nicht_verifiziert: 70, verifiedEmails: 40 },
  { date: "2024-10-21", nicht_verifiziert: 80, verifiedEmails: 56 },
  { date: "2024-10-24", nicht_verifiziert: 60, verifiedEmails: 53 },
  { date: "2024-10-25", nicht_verifiziert: 70, verifiedEmails: 52 },
  { date: "2024-10-26", nicht_verifiziert: 80, verifiedEmails: 50 },
];
const staticDataForSentAndReceivedEmail = [
  { date: "2024-10-08", sentEmails: 300, receivedEmails: 250 },
  { date: "2024-10-02", sentEmails: 400, receivedEmails: 320 },
  { date: "2024-10-07", sentEmails: 400, receivedEmails: 820 },
  { date: "2024-10-28", sentEmails: 40, receivedEmails: 620 },
  { date: "2024-10-29", sentEmails: 400, receivedEmails: 620 },
  { date: "2024-10-22", sentEmails: 500, receivedEmails: 120 },
  { date: "2024-10-21", sentEmails: 300, receivedEmails: 220 },
  { date: "2024-10-24", sentEmails: 600, receivedEmails: 320 },
  { date: "2024-10-25", sentEmails: 200, receivedEmails: 520 },
  { date: "2024-10-26", sentEmails: 700, receivedEmails: 420 },
];
const participationData = [
  { date: "2024-10-08", assignmentSubmissions: 30, attendance: 25 },
  { date: "2024-10-02", assignmentSubmissions: 40, attendance: 32 },
  { date: "2024-10-07", assignmentSubmissions: 45, attendance: 82 },
  { date: "2024-10-28", assignmentSubmissions: 42, attendance: 60 },
  { date: "2024-10-29", assignmentSubmissions: 50, attendance: 62 },
  { date: "2024-10-22", assignmentSubmissions: 52, attendance: 12 },
  { date: "2024-10-21", assignmentSubmissions: 30, attendance: 22 },
  { date: "2024-10-24", assignmentSubmissions: 60, attendance: 32 },
  { date: "2024-10-25", assignmentSubmissions: 20, attendance: 52 },
  { date: "2024-10-26", assignmentSubmissions: 70, attendance: 42 },
  // Add more data points as needed
];
const salesData = [
  {
    name: "Jan 23",
    Ausgaben: 38,
    Einnahmen: 12,
  },
  {
    name: "Feb 23",
    Ausgaben: 40,
    Einnahmen: 32,
  },
  {
    name: "Mar 23",
    Ausgaben: 58,
    Einnahmen: 43,
  },
  {
    name: "Apr 23",
    Ausgaben: 14,
    Einnahmen: 34,
  },
  {
    name: "May 23",
    Ausgaben: 11,
    Einnahmen: 58,
  },
  {
    name: "Jun 23",
    Ausgaben: 36,
    Einnahmen: 26,
  },
  {
    name: "Jul 23",
    Ausgaben: 27,
    Einnahmen: 39,
  },
  {
    name: "Aug 23",
    Ausgaben: 37,
    Einnahmen: 50,
  },
  {
    name: "Sep 23",
    Ausgaben: 28,
    Einnahmen: 38,
  },
  {
    name: "Oct 23",
    Ausgaben: 52,
    Einnahmen: 30,
  },
  {
    name: "Nov 23",
    Ausgaben: 19,
    Einnahmen: 9,
  },
  {
    name: "Dec 23",
    Ausgaben: 32,
    Einnahmen: 42,
  },
];
const emailsPerSchool = [
  {
    name: "Jan 23",
    emails: 38,
    school_title: 12,
  },
  {
    name: "Feb 23",
    emails: 40,
    school_title: 32,
  },
  {
    name: "Mar 23",
    emails: 58,
    school_title: 43,
  },
  {
    name: "Apr 23",
    emails: 14,
    school_title: 34,
  },
  {
    name: "May 23",
    emails: 11,
    school_title: 58,
  },
  {
    name: "Jun 23",
    emails: 36,
    school_title: 26,
  },
  {
    name: "Jul 23",
    emails: 27,
    school_title: 39,
  },
  {
    name: "Aug 23",
    emails: 37,
    school_title: 50,
  },
  {
    name: "Sep 23",
    emails: 28,
    school_title: 38,
  },
  {
    name: "Oct 23",
    emails: 52,
    school_title: 30,
  },
  {
    name: "Nov 23",
    emails: 19,
    school_title: 9,
  },
  {
    name: "Dec 23",
    emails: 32,
    school_title: 42,
  },
];
export default {
  data,
  dataSupportTicket,
  dataEmails,
  staticData,
  staticDataForRegisteredClasses,
  incomeData,
  staticDataSchool,
  staticDataForSentEmail,
  staticDataEmail,
  staticDataSupprtTicket,
  schoolData,
  emailSlotData,
  subscriptionData,
  activityData,
  staticDataForEmails,
  staticDataForSentAndReceivedEmail,
  participationData,
  salesData,
  emailsPerSchool,
  emailPerSchool,
};
