import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight, FaCog } from "react-icons/fa";
import ProfileCard from "../../components/profile-card";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from "date-fns";
import { IoIosClose } from "react-icons/io";
import { DatePicker } from "antd";
import AllSchoolTable from "../../components/allSchoolTable";
import useGetSchool from "../../hooks/useGetSchool";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
const getWeekRange = (date) => {
  const start = startOfWeek(date, { weekStartsOn: 1 });
  const end = endOfWeek(date, { weekStartsOn: 1 });
  return [start, end];
};

const getMonthRange = (date) => {
  const start = startOfMonth(date);
  const end = endOfMonth(date);
  return [start, end];
};
const AlleSchulen = () => {
  const [isActive, setIsActive] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dateRange, setDateRange] = useState([]);
  const { GetSchool, loading, tableData } = useGetSchool();
  const rowsPerPage = 10;
  const { RangePicker } = DatePicker;

  const tableHeader = [
    "Schulname",
    "Ansprechpartner",
    "E-Mail-Adresse",
    "Telefonnummer",
    "Abonnementstatus",
    "Anzahl der Klassen",
    "Anmeldedatum",
  ];

  const handleDateRangeChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setDateRange([start ? dayjs(start) : null, end ? dayjs(end) : null]);
    } else {
      setDateRange([]);
    }
  };

  const filteredQuestions = tableData.filter((item) => {
    const itemDate = dayjs(item.registration_date);
    const today = dayjs();
    let isInDateRange = false;

    switch (isActive) {
      case 1: // Tag
        isInDateRange = itemDate.isSame(today, "day");
        break;
      case 2: // Woche
        const [weekStart, weekEnd] = getWeekRange(today.toDate());
        isInDateRange =
          itemDate.isSameOrAfter(dayjs(weekStart)) &&
          itemDate.isSameOrBefore(dayjs(weekEnd));
        break;
      case 3: // Monat
        const [monthStart, monthEnd] = getMonthRange(today.toDate());
        isInDateRange =
          itemDate.isSameOrAfter(dayjs(monthStart)) &&
          itemDate.isSameOrBefore(dayjs(monthEnd));
        break;
      default:
        isInDateRange = true;
    }

    return (
      item.school_name?.toLowerCase().includes(searchTerm.toLowerCase()) &&
      isInDateRange
    );
  });
  const totalPages = Math.ceil(filteredQuestions.length / rowsPerPage);
  const currentData = filteredQuestions.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };
  useEffect(() => {
    GetSchool(isActive, dateRange);
  }, [isActive, dateRange]);

  return (
    <main>
      <div className="flex flex-col md:flex-row justify-between items-center gap-3">
        <h2 className="text-xl font-bold dark:text-white">Alle Schulen</h2>
        <div className="flex md:flex-row flex-col justify-end items-center gap-5">
          <div className="flex justify-end items-center">
            <button
              onClick={() => setIsActive(1)}
              className={`${
                isActive === 1
                  ? "bg-blue-500 text-white border-blue-500 dark:bg-gray-700"
                  : "bg-white dark:bg-gray-600"
              } hover:bg-blue-500 hover:text-white border  border-gray-300 rounded px-3 py-1 text-sm dark:text-white`}
            >
              Tag
            </button>
            <button
              onClick={() => setIsActive(2)}
              className={`${
                isActive === 2
                  ? "bg-blue-500 text-white border-blue-500 dark:bg-gray-700 "
                  : "bg-white dark:bg-gray-600"
              } hover:bg-blue-500  hover:text-white border border-gray-300 rounded px-3 py-1 text-sm dark:text-white`}
            >
              Woche
            </button>
            <button
              onClick={() => setIsActive(3)}
              className={`${
                isActive === 3
                  ? "bg-blue-500 text-white border-blue-500  dark:bg-gray-700"
                  : "bg-white dark:bg-gray-600"
              } hover:bg-blue-500  hover:text-white border border-gray-300 rounded px-3 py-1 text-sm dark:text-white`}
            >
              Monat
            </button>
            {isActive !== 0 && (
              <IoIosClose
                className="text-[18px] text-gray-800 dark:text-white ml-1 cursor-pointer"
                onClick={() => setIsActive(0)}
              />
            )}
          </div>
          <RangePicker className="w-[200px]" onChange={handleDateRangeChange} />
        </div>
      </div>

      <div className="input-holder w-full rounded-[6px] overflow-hidden bg-gray-100 dark:bg-gray-600 flex justify-between items-center mt-5 mb-8">
        <p className="text-[12px] py-3 text-left lg:w-[200px] w-[100px] px-3 dark:text-white">
          Suchen
        </p>
        <input
          type="text"
          className="input outline-none border-none p-3 bg-gray-200 dark:bg-gray-500 dark:text-white dark:placeholder:text-gray-200 w-full rounded-[6px] text-[12px] lg:text-[16px]"
          placeholder="Suchen Sie nach einem bestimmten Ergebnis..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="md:grid flex flex-col md:mt-8 mt-4 mb-4 grid-cols-2 gap-5 rounded-[8px] overflow-hidden">
        <div className="card flex flex-col justify-between shadow col-span-2 h-full bg-white dark:bg-gray-700">
          <AllSchoolTable
            tableHeader={tableHeader}
            currentData={currentData}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            loading={loading}
          />
        </div>
      </div>
      {/* <div className="card bg-white dark:bg-gray-700 p-4 rounded-[8px]">
        <p className="text-lg font-bold mb-6 dark:text-white">
          Nachrichten der Schulen
        </p>
        <ProfileCard name="Herr Müller" id="01223" />
        <ProfileCard name="Herr Müller" id="01223" />
        <ProfileCard name="Herr Müller" id="01223" />
        <ProfileCard name="Herr Müller" id="01223" />
        <ProfileCard name="Herr Müller" id="01223" />
        <ProfileCard name="Herr Müller" id="01223" />
        <ProfileCard name="Herr Müller" id="01223" />
        <ProfileCard name="Herr Müller" id="01223" />
        <ProfileCard name="Herr Müller" id="01223" />
        <ProfileCard name="Herr Müller" id="01223" />
        <ProfileCard name="Herr Müller" id="01223" />
        <ProfileCard name="Herr Müller" id="01223" />

        <Link
          to="/"
          className="text-blue-500 mt-10 font-semibold text-sm flex items-center gap-2"
        >
          Mailgun Updaten <FaChevronRight />
        </Link>
      </div> */}
    </main>
  );
};

export default AlleSchulen;
